import { RequestService } from './request.service';

const requestService = new RequestService();
let requestV1: RequestService;
let requestV2: RequestService;

export default function FinancesRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  requestV1 = requestService.new(
    `${baseUrl['API_MAIN']}/v1/finance/payment`,
    useInterceptor,
    withoutInterceptor
  );

  requestV2 = requestService.new(
    `${baseUrl['API_MAIN']}/v2/finances/payments`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    createPaymentV2(payload: any) {
      return requestV2.post('create', payload);
    },

    uploadAttachment(payload: any) {
      return requestV2.post('upload-attachment', payload);
    },

    cancelPayment(id) {
      return requestV1.delete(`${id}`);
    },

    reviewPayment(id, payload) {
      return requestV1.put(`${id}`, payload);
    },

    getPayment(params: any) {
      return requestV1.get('payment-method', { params });
    },

    downloadAttachment(filename: string) {
      return requestV1.get<any>(`download-attachment/${filename}`);
    },

    findById(id) {
      return requestV1.get<any>(`${id}`);
    },

    getPendingPayment() {
      return requestV2.get<any>('/pending');
    },

    cancelPaymentV2(body) {
      return requestV2.delete<any>('cancel', body);
    }
  }

}
