import { notification } from 'antd';
import { MessageParserService } from '../messageService';
import { INotification } from './notification';
export class Notifications {
  msgService: MessageParserService = new MessageParserService;
  /**
   *
   * @param params
   * type: 'success' | 'info' | 'warning' | 'error';
   * description: any;
   * useService?: boolean;;
   */
  show(params: INotification) {
    notification[params.type]({
      message: `${params.title}`,
      className: `notif-deo notif-deo-${params.type}`,
      description: !params.useService ? `${params.description}` : this.msgService.parse(params.description),
    });
  }
}
