import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function TopupRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/finance`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    getAllTopUp(params) {
      return request.get<any>('topup', params);
    },

    getAllPaymentMethod(params) {
      return request.get<any>('payment/payment-method', params);
    },

    createTopUp(payload) {
      return request.post<any>('topup', payload);
    },

    uploadAttachmentTopup(payload) {
      return request.post<any>('topup/manual/attachment', payload);
    },

    getTopupAttachment(id) {
      return request.get<any>(`topup/${id}/manual/attachment-url`);
    },

    getDetailPayment(id) {
      return request.get<any>(`payment/ref-number/${id}`);
    }
  }
}
