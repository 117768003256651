export interface IStateReducer {
  [key: string]: any;
}

export interface IActionReducer {
  type: 'update' | 'delete';
  name: string;
  value: any;
}

export default (state: IStateReducer, action:IActionReducer) => {
  switch (action.type) {
    case "update":
      return {
        ...state,
        [action.name]: action.value,
      };
    case "delete":
      delete state[action.name];
      return state;
    default:
      return state;
  }
};
