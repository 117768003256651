import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function DepartmentRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    getAll(params) {
      return request.get<any>('/v1/department', params);
    },

    getById(id: string) {
      return request.get<any>(`/v1/department/${id}`);
    },

    create(body: any) {
      return request.post('/v1/department/create', body);
    },

    update(body: any, id: string) {
      return request.put(`/v1/department/${id}/update`, body);
    },

    delete(id: string) {
      return request.delete(`/v1/department/${id}/delete`);
    },

    import(payload) {
      return request.post<any>(`/v1/department/imports`, payload);
    }
  }
}
