import React from 'react';
import { Icon } from '../../foundations/icon/icon.component';
import { ButtonProps } from './button';

const Button = (props: ButtonProps) => {
  return (
    <React.Fragment>
      <button
        id={props.id}
        type={props.submit ? 'submit' : 'button'}
        onClick={props.onClick}
        disabled={props.disabled}
        className={
          `
          btn btn-deo-${props.type || 'x'}
          btn-deo-${props.size || 'md'}
          ${props.disabled ? ' disable ' : ''}
          ${props.activate ? 'is-active' : ''}
          ${props.circle ? 'circle' : ''}
          ${props.danger ? 'danger' : ''}
          ${props.className || ''}
          `
        }
      >
        {
          (props.iconPosition != 'right' && props.iconName) &&
          <Icon name={props.iconName} size={props.size || 'sm'} className={props.label && 'mr-1 my-auto'} />
        }
        {props.label}
        {
          (props.iconPosition == 'right' && props.iconName) &&

          <Icon name={props.iconName} size={props.size} className={props.label && 'ml-1 my-auto'} />
        }
      </button>
    </React.Fragment>
  );
}

export { Button };
