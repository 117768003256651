import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;
let requestV2: RequestService;

export default function ManageUserRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
  request = requestService.new(
    `${baseUrl['API_MAIN']}`,
    useInterceptor,
    withoutInterceptor
  );
  requestV2 = requestService.new(
    `${baseUrl['API_MAIN']}/v2`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    // unspesific version
    getBusinessTypes() {
      return request.get<any>(`bussiness-type`);
    },

    getLocations(payload) {
      return request.post<any>(`locations`, payload);
    },

    getPostalCode(payload) {
      return request.post<any>(`postalcode`, payload);
    },

    getSales(params: any) {
      return request.get<any>('sales', { params });
    },

    saveUTM(payload) {
      return request.post<any>('utm', payload);
    },


    // v1
    getBusinessFileds() {
      return request.get<any>(`v1/company/bussinessType`);
    },

    // v2
    generateOTP(type, payload) {
      return requestV2.post<any>(`${type.includes('phone') ? `user/otp/send-otp` : `setting/profile/email/send-otp`}`, payload);
    },

    getUserUpgrade() {
      return requestV2.get<any>(`user/upgrade`);
    },

    register(payload: any) {
      return requestV2.post<any>(`user/register`, payload);
    },

    updateUserUpgrade(payload) {
      return requestV2.put<any>(`user/upgrade`, payload);
    },

    userOTP(payload: any) {
      return requestV2.post<any>(`user/otp`, payload);
    },

    userOtpVerify(payload: any) {
      return requestV2.post<any>(`user/otp/verify`, payload);
    },

    userVerify(payload) {
      return requestV2.post<any>(`user/verify`, payload);
    },

    validateOTP(type, payload: any) {
      const target = type.includes('phone') ? requestV2.post<any>(`user/otp/validate`, payload) : requestV2.patch<any>(`setting/profile/email`, payload);
      return target;
    },
  }
}
