import React, { useEffect, useState } from 'react';
import { HandleService } from '@clodeo/clodeo-core/handle/handle.service';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/auth/authentication.service';
import { List } from 'antd';
import './notification.component.scss';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { UserRest } from '@clodeo/libs/core/rest';

export default function NotificationComponent(props) {
  const [notificationList, setNotificationList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleService: HandleService = new HandleService();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const { checkNotification } = UserRest(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  useEffect(() => {
    checkInvoiceNotification(false);
    return () => checkInvoiceNotification(true)
  }, []);

  const checkInvoiceNotification = (param) => {
    setIsLoading(true);
    const params = {
      isRead: true,
    };
    if(param) props.onOpenNotif()
    
    const obs = checkNotification(param? params : null);
    handleService.handleRequest({
      obs,
      onDone: (res) => {
        if (res?.data) {
          const datas = res?.data;
          setNotificationList(datas);
          setIsLoading(false);
        }
      },
      onError: (err) => {
        setIsLoading(false);
      },
    });
  };

  return (
    <Spinner spinning={isLoading}>
      <div>
        <List
          size="large"
          dataSource={notificationList}
          className="notification-list"
          renderItem={(data: any) => <List.Item>{data?.message}</List.Item>}
        ></List>
      </div>
    </Spinner>
  );
}
