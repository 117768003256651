import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function TrackingAirwaybillRest(
  baseUrl,
  useInterceptor: any = true,
  withoutInterceptor: boolean = false
) {
  request = requestService.new(
    `${baseUrl['API_MAIN']}/v2/airwaybill`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    getTracking(awb) {
      return request.get(`${awb}`);
    },
  };
}
