import React, { useEffect, useState } from 'react';

import { split } from 'lodash';

import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Menu } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';

import useMenus from '../../../hooks/useMenus';

import './menu-layout.component.scss';

export const MenuComponent = (props: MenuProps) => {
  // showSubs use for show dropdown
  const [showSubs, setShowSubs] = useState(['shipment']);
  // showActiveSub use for avtive submenu
  const [showActiveSub, setShowActiveSub] = useState<string>();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { menus } = useMenus();

  function isActive(to: string, index) {
    const path = split(props?.pathName, '/');
    const current = split(to, '/');

    return path[index] === current[index];
  }

  const handleDropdown = (item) => {
    if (showSubs.includes(item)) {
      setShowSubs(showSubs.filter(val => val !== item));
    } else {
      setShowSubs([...showSubs, item]);
    }
  }

  useEffect(() => {
    const currentPath = pathname.split('/');

    if (menus.length) {
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].id === currentPath[2]) {
          setShowActiveSub(menus[i].id);
          setShowSubs([...showSubs, menus[i].id]);

          break;
        } else {
          setShowActiveSub(null);
        }
      }
    }
  }, [pathname]);

  return (
    menus && (
      <Menu
        className="menu-layout-admin-deo"
        mode="inline"
      >
        <div className="d-flex flex-column justify-content-between">
          {(menus || []).map((menu: IMenu, i: number) => (
            <div key={i}>
              {menu?.subMenu ? (
                <div>
                  <div className={`wrapper-nav-link d-flex justify-content-between pointer ${showActiveSub?.includes(menu.id) ? 'active' : ''}`} onClick={() => handleDropdown(menu.id)}>
                    {menu.image ? (
                      <div className={showActiveSub?.includes(menu.id) ? 'tab-image-active' : 'tab-image'}>
                        {menu.image}
                      </div>
                    ) : (
                      <div className="icon-wrapper">
                        <Icon name={menu.icon} />
                      </div>
                    )}
                    <div className="label pl-2 lh-16 mr-auto">{t(menu.label)}</div>
                    <div className="icon-arrow">
                      <Icon name={showSubs.includes(menu.id) ? "i-CaretUp" : "i-CaretDown"} />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="line-submenu"></div>
                    <div className="relative">
                      {showSubs.includes(menu.id) && menu?.subMenu.map((val, index) => (
                        <NavLink
                          key={index}
                          to={val.to}
                          className="wrapper-nav-link submenu"
                          isActive={() => isActive(val.to, 3)}
                          activeClassName="submenu-active relative"
                          onClick={() => setShowActiveSub(menu.id)}
                        >
                          {isActive(val.to, 3) && (
                            <div className="sidebar-dot">
                              <div className="sidebar-donut">
                                <div className="sidebar-hole"></div>
                              </div>
                            </div>
                          )}
                          <div className="icon-wrapper">
                            <Icon name={val.icon} />
                          </div>
                          <div className="label lh-16">{t(val.label)}</div>
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <NavLink
                  to={menu.to}
                  className="wrapper-nav-link"
                  isActive={() => isActive(menu.to, 2)}
                  activeClassName="active"
                  onClick={() => setShowActiveSub(null)}
                >
                  {menu.image ? (
                    <div className={showActiveSub?.includes(menu.id) ? 'tab-image-active' : 'tab-image'}>
                      {menu.image}
                    </div>
                  ) : (
                    <div className="icon-wrapper">
                      <Icon name={menu.icon} />
                    </div>
                  )}
                  <div className="label pl-2 lh-16">{t(menu.label)}</div>
                </NavLink>
              )}
            </div>
          ))}
        </div>

      </Menu >
    )
  );
};
