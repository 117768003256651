import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function UserDepartmentRest(baseUrl, useInterceptor: any = true, withoutInterceptor: any = false) {
  request = requestService.new(`${baseUrl['API_MAIN']}/v1/department`, useInterceptor, withoutInterceptor);

  return {
    getAllDepartement(params){
      return request.get<any>('', params);
    },
  
    getDepartementById(id: string){
      return request.get<any>(id);
    },
  
    createDepartement(body: any){
      return request.post('create', body);
    },
  
    updateDepartement(body: any, id: string){
      return request.put(`${id}/update`, body);
    },
  
    deleteDepartement(id: string){
      return request.delete(`${id}/delete`);
    },
  
    importDepartment(payload) {
      return request.post<any>(`imports`, payload);
    }
  }
}
