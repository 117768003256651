import moment from 'moment';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../../apps/portal/src/environments/environment';


export class MixpanelService {
  boot(user) {
    mixpanel.init(environment.MIXPANEL_TOKEN, { debug: true });
    if (user && user.userId) {
      const data = {
        $distinct_id: user?.userId,
        $name: user?.fullName,
        'Company Id': user?.userCompany?.companyId,
        'Company Name': user?.userCompany?.companyName,
        $email: user.email,
      };
      mixpanel.identify(user.userId);
      mixpanel.register_once({
        'First Login Date': moment(),
      });
      mixpanel.register(data);
      mixpanel.people.set(data);
    }
    mixpanel.track('First Load', { ...user });
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    action.platform = 'Portal';
    mixpanel.track(id, action);
  }

  logout() {
    mixpanel.reset();
  }
}
