import { createContext } from 'react';
import { IActionReducer } from '../reducers/localdata';

export interface ILocalDataContext {
  store: any;
  dispatch: React.Dispatch<IActionReducer>;
}

const LocalDataContext = createContext<ILocalDataContext>({} as ILocalDataContext);

export default LocalDataContext;
