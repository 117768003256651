import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;
let requestV2: RequestService;

export default function SettingRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1`,
    useInterceptor,
    withoutInterceptor
  );
  requestV2 = requestService.new(
    `${baseUrl['API_MAIN']}/v2`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    // v1
    getDetail() {
      return request.get('setting/detail');
    },

    setDetailCompany(payload) {
      return request.put('setting/info/edit', payload);
    },

    setContactSetting(payload) {
      return request.put('setting/contact/edit', payload);
    },

    setAddressSetting(payload) {
      return request.put('setting/address/edit', payload);
    },

    setBankSetting(payload) {
      return request.put('setting/bank/edit', payload);
    },

    setAuthorizationSetting(payload) {
      return request.put('setting/password/edit', payload);
    },

    updateConfigurationCompany(payload) {
      return request.put('setting/company/config', payload);
    },

    getClient() {
      return request.get<any>(`apps`);
    },

    getClientById(id) {
      return request.get<any>(`apps?id=${id}`)
    },

    getAllClient(params) {
      return requestV2.get<any>('apps/list', { params });
    },

    getCourier() {
      return request.get<any>(`couriers`);
    },

    getType() {
      return request.get<any>(`apps/types`);
    },

    createApp(payload) {
      return request.post<any>(`apps`, payload);
    },

    updateApp(id, payload) {
      return request.put<any>(`apps/${id}`, payload);
    },

    updateExpedition(data) {
      return requestV2.put<any>('apps/expedition', data);
    },

    checkCourierActive() {
      return requestV2.get<any>('apps/expedition/courier');
    },

    refreshApp(id) {
      return request.put<any>(`apps/refresh/${id}`);
    },

    getCompany() {
      return request.get<any>('company');
    },

    // v2
    getProfile() {
      return requestV2.get<any>('setting/profile');
    },

    updateProfile(payload) {
      return requestV2.put<any>('setting/profile/update', payload);
    },

    updateProfilePassword(payload) {
      return requestV2.put<any>('setting/profile/update-password', payload);
    },

    getShippingLabelConfig() {
      return requestV2.get<any>('shipping-label/config');
    },

    updateShippingLabelConfig(config) {
      return requestV2.post<any>('shipping-label/config', config);
    }
  }
}
