import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function ContactRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/contact`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    create(payload) {
      return request.post<any>('', payload);
    },

    createBatch(payload) {
      return request.post<any>('batch', payload);
    },

    update(id, payload) {
      return request.patch<any>(`/${id}`, payload);
    },

    remove(id) {
      return request.delete<any>(`/${id}`);
    },

    importContact(payload) {
      return request.post<any>(`/imports`, payload);
    },

    getAll(params, id?) {
      return request.get<any>('', {
        params: {
          ...params,
          companyId: id ? id : '',
        },
      });
    }
  }
}
