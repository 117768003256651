import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;
let requestV2: RequestService;
let requestMain: RequestService;

export default function CourierRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1`,
    useInterceptor,
    withoutInterceptor
  )

  requestV2 = requestService.new(
    `${baseUrl['API_MAIN']}/v2`,
    useInterceptor,
    withoutInterceptor
  )

  requestMain = requestService.new(
    `${baseUrl['API_SHIPDEO_MAIN']}`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    getListCouriers() {
      return request.get<any>('/couriers');
    },

    updateOrder(id: string, payload: any) {
      return requestMain.put<any>(`/couriers/orders/${id}`, payload);
    },

    getOrder(id: string) {
      return requestMain.get(`/couriers/orders/${id}`);
    },

    getCouriersPricing(payload) {
      return requestMain.post<any>('/couriers/pricing', payload);
    },

    getPricing(payload) {
      return request.post<any>('/pricing/all', payload);
    },

    getPricingV2(payload){
      return requestV2.post<any>('/pricing/all', payload);
    },

    checkCoverageCod(payload) {
      return request.post<any>('/cod/coverage', payload);
    }

  }
}
