export class EventService {
  listen(eventName: string, listener) {
    document.addEventListener(eventName, listener);
  }
  
  destroy(eventName: string, listener) {
    document.removeEventListener(eventName, listener);
  }
  
  listenOnce(eventName: string, listener) {
    this.listen(eventName, handleEventOnce);
  
    function handleEventOnce(event) {
      listener(event);
      this.destroy(eventName, handleEventOnce);
    }
  }
  
  emit(eventName: string, data) {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
  }
}
