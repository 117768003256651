import React from 'react';

import { Input as BaseInput } from 'antd';
import { InputProps, TextAreaProps } from './inputtext';
import { SearchProps } from 'antd/lib/input';
const { TextArea, Search } = BaseInput;
const Input = (props: InputProps) => (
  <BaseInput
    {...props}
    size={props.size ? props.size : props.size == 'medium' ? 'middle' : 'middle'}
    className={'w-100 input-deo ' + (props.className || '')}
  />
)
const InputTextArea = (props: TextAreaProps) => (
  <TextArea
    {...props}
    className={'w-100 input-deo ' + (props.className || '')}
  />
)

const InputSearch = (props: SearchProps) => (
  <Search
    {...props}
    className={'w-100 input-deo ' + props.className}
  />
)
const InputPassword = (props) => (
  <BaseInput.Password
    {...props}
    size={props.size ? props.size : props.size == 'medium' ? 'middle' : 'middle'}
    className={'w-100 input-deo ' + (props.className || '')}
  />
)
export { Input, InputTextArea, InputPassword, InputSearch }
