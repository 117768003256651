import React from 'react';
import { IconProps } from './icon';

// import { Tooltip } from 'primereact/tooltip';

const Icon = (props: IconProps) => (
    <i
        // data-pr-tooltip={`${props.tooltip}`}>
        className={'icon-' + props.name + ' icon-' + (props.size === 'xsm' ? '13px ' : props.size === 'sm' ? '14px ' : props.size === 'lg' ? '16px ' : '15px ') + (props.className || '')}>

    </i>
);


export { Icon };
