import React from 'react';
import { Tabs as BaseTabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
const { TabPane } = BaseTabs;

const Tabs = (props: TabsProps) => {
  const { className, ...rest } = props;
  return <BaseTabs
    {...rest}
    className={`deo-tab-basic ${className || ''}`}
  />
}

export {
  Tabs, TabPane
}
