import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

import Cookies from 'js-cookie';
import { compact, isEmpty, isNil, startCase } from 'lodash';
import OneSignal from 'react-onesignal';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useGoogleLogout } from 'react-google-login';
import i18n from '@clodeo/libs/core/translate/i18n';

import { Layout, Divider } from 'antd';
import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';
import { TabPane, Tabs } from '@clodeo/clodeo-ui/components/data-display/tabs/tabs.component';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import { Item, Menu } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';

import { HandleService } from '@clodeo/clodeo-core/handle/handle.service';
import { LocalStorageService } from '@clodeo/clodeo-core/local-storage/local-storage.service';
import { UserRest } from '@clodeo/libs/core/rest';

import { MultiResiOrder, SearchOrder } from './../main/main-layout.component'
import NotificationComponent from '../../extra-component/notification-component/notification.component';

import useMenus from '../../../hooks/useMenus';
import useLayout from '../../../hooks/useLayout';
import useLocalData from '../../../hooks/useLocalData';
import { AuthenticationService } from '../../../core/auth/authentication.service';

import { environment } from '../../../../environments/environment';

import './header-layout.component.scss';
import { AclService } from '../../../core/auth/acl.service';
import { isShowVerificationPopup } from 'apps/portal/src/app/components/VerificationPopup/utils';
import { Routes, SubMenus } from 'apps/portal/src/app/routes/routes';
import { BalanceAmount } from '../../BalanceAmount';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';

const { Header } = Layout;
const { can } = new AclService();
export const HeaderLayoutComponent = (props: {
  pathName?: string;
}) => {
  const [unReadNotification, setUnreadNotification] = useState<boolean>(false);
  const handleService: HandleService = new HandleService();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const localStorage = new LocalStorageService();
  const { checkNotification } = UserRest(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const { signOut } = useGoogleLogout({ clientId: environment.KEYS.GOOGLE_OAUTH });
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const router = useHistory();
  const { parentMenu } = useMenus();
  const { store, dispatch } = useLocalData();
  const { balanceData, relatedData } = store || {};
  const { store: layoutStore, dispatch: dispatchLayout } = useLayout();


  const [title, setTitle] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>(props.pathName);
  const [searchActive, setSearchActive] = useState<boolean>(false);

  const { headerAction, currentUser } = store || {};
  function onLogout() {
    OneSignal?.setSubscription(false)
    if (currentUser?.isLoginByGoogle) signOut();
    if (localStorage.getItem('userClosedPromo') === true) localStorage.removeItem('userClosedPromo');
    new AuthenticationService().logout();
  }

  useEffect(() => {
    let link = props.pathName;
    if (!isNil(parentMenu)) {
      setTitle(parentMenu?.label);
      link = parentMenu?.to;
    }

    setActiveTab(link);
  }, [parentMenu, props.pathName]);


  useEffect(() => {
    loadNotification();
  }, []);

  const loadNotification = () => {
    const obs = checkNotification();
    handleService.handleRequest({
      obs,
      onDone: (res) => {
        if (res?.data) {
          const datas: any[] = res?.data;
          const isRead = datas.filter((data) => data.isRead === false);
          if (isRead.length > 0) {
            setUnreadNotification(true);
          }
        }
      },
      onError: (err) => { },
    });
  };


  useEffect(() => {
    // currently we have two title on header so if the title from the old we should hide headerAction
    if (!props.pathName.includes('shipment-order') && !props.pathName.includes('order')) {
      dispatch({
        type: 'update',
        name: 'headerAction',
        value: null
      })
    }

    // check expired dashboard data
    new Date().getTime() > localStorage.getItem('dashboardShipment')?.expired && localStorage.removeItem('dashboardShipment');
    new Date().getTime() > localStorage.getItem('dashboardDelivery')?.expired && localStorage.removeItem('dashboardDelivery');
    new Date().getTime() > localStorage.getItem('dashboardTopRate')?.expired && localStorage.removeItem('dashboardTopRate');
  }, [props.pathName])

  function redirectTo(to: string) {
    router.push(to);
  }

  const dropdown = (
    <Menu>
      <Item className="dropdown-profile">
        <div className="d-flex align-items-center gap-8px">
          {relatedData?.userCompany?.companyPicture ? (
            <img width="30px" height="30px" className="dropdown-img-profile" src={relatedData?.userCompany?.companyPicture} alt="#" />
          ) : (
            <img width="30px" height="30px" className="dropdown-img-profile" src="assets/img/Icons/icon-avatar.svg" alt="#" />
          )}
          <div className="d-flex flex-column">
            <div className="user-profile">{currentUser?.user?.fullName}</div>
            <div className="status-user">{relatedData?.userType === 'B2B' ? 'Member Business' : 'Personal'}</div>
          </div>
        </div>
      </Item>
      <Item className="sub-dropdown-profile">
        <div className="sub-menu-title pl-1">Account</div>
      </Item>
      <Item className="pl-3 pr-5 dropdown-item" onClick={() => redirectTo(Routes.PROFILE)}>Profile</Item>
      {isShowVerificationPopup(relatedData) && (
        <Item className="pl-3 pr-5 dropdown-item" onClick={() => redirectTo(Routes.VERIFICATION)}>Verifikasi Akun</Item>
      )}
      {(can(['balance.balance_history']) && relatedData?.userCompany?.companyIsBalanceShow) && (
        <Item className="pl-3 pr-5 dropdown-item" onClick={() => redirectTo(Routes.BALANCE)}>Balance</Item>
      )}
      {can(['finance.invoice.view']) && relatedData?.userType === 'B2B' && relatedData?.userCompany?.companyIsAutoBilling && (
        <Item className="pl-3 pr-5 dropdown-item" onClick={() => redirectTo(Routes.INVOICE)}>Tagihan</Item>
      )}
      <Item className="sub-dropdown-profile">
        <Divider className="my-1" />
      </Item>
      <Item className="text-danger pl-3 pr-5 dropdown-item" onClick={() => onLogout()}>Log Out</Item>
    </Menu>
  );

  const translateDropdown = (
    <div className="translate-dropdown pl-2 pt-2">
      <p
        key="en"
        style={{ cursor: 'pointer' }}
        onClick={() => handleChangeLanguage('en')}
      >
        English
      </p>
      <p
        key="id"
        style={{ cursor: 'pointer' }}
        onClick={() => handleChangeLanguage('id')}
      >
        Indonesian
      </p>
    </div>
  );

  function handleChangeLanguage(lang) {
    let cookiesLang = 'id';
    switch (lang) {
      case 'en':
        i18n.changeLanguage('en');
        cookiesLang = lang;
        break;
      case 'id':
        i18n.changeLanguage('id');
        cookiesLang = lang;
        break;
      default:
        break;
    }
    Cookies.set('i18nLang', cookiesLang, { expires: 7 });
  }

  function handleCheckAirwaybill(event) {
    if (event.target.value) router.push(`${Routes.CHECK_AIRWAYBILL}?awb=${event.target.value}`);
  }

  function isFirstCharacterNumber(value: any) {
    const firstChar = value.charAt(0);

    return !isNaN(firstChar);
  }

  function conditionalHideTabMenu(): boolean {
    return router.location.pathname.includes('/finance/balance') || router.location.pathname.includes('/user/setting');
  }

  useEffect(() => {
    setTimeout(() => {
      dispatchLayout({
        type: 'update',
        name: 'headerHeight',
        value: ref.current?.clientHeight + 30 || 0,
      });
    }, 50);
  }, [ref.current, props.pathName]);

  useEffect(() => {
    setSearchActive(false)
  }, [router?.location?.pathname])

  return (
    <Header className="no-select-text header-admin-deo px-3 py-0" style={{ width: 'calc(100% - ' + layoutStore?.siderWidth + 'px)' }}>
      <div ref={ref}>

        <div className="d-flex justify-content-between px-2 pb-1 pt-3">
          <div className="d-flex px-0">
            {headerAction ? (
              <div className="d-flex align-items-center header-action" style={{ gap: '10px' }}>
                {headerAction?.prevPage && (
                  <Link to={headerAction?.prevPage} className="d-flex">
                    <Icon name="i-CaretLeft" className="back" />
                  </Link>
                )}

                <label className="title mb-0">{t(headerAction?.title) || ''}</label>

                {/* {headerAction?.subTitle && (
                <>
                  <Divider type="vertical" />
                  <label className="sub-title mb-0">{t(headerAction?.subTitle) || ''}</label>
                </>
              )} */}

                {/* Additional Content is for using showing the element such as children */}
                {headerAction?.additionalContent && (
                  <>
                    <Divider type="vertical" />
                    <div>
                      <span className="pr-2">{headerAction?.additionalContent?.title}</span> {headerAction?.additionalContent?.element}
                    </div>
                  </>
                )}

                {(headerAction?.buttons || []).map((props: any, i) => {
                  const { dropdown, ...restProps } = props;
                  return (
                    <React.Fragment key={`header-btn-${i}`}>
                      <Divider type="vertical" />
                      <div>
                        {isEmpty(dropdown || {}) && (
                          <Button {...restProps} />
                        )}
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
            ) : <>
              {parentMenu?.subMenu?.length && (
                <div className="d-flex align-items-center header-action" style={{ gap: '10px' }}>
                  <label className="title mb-0">
                    {t(parentMenu.label) || ''}
                  </label>
                  <Divider type="vertical" />
                  <label className="sub-title mb-0">{t(title) || ''}</label>
                </div>
              )}
              {!parentMenu?.subMenu?.length && (
                <div className="d-flex align-items-center header-action" style={{ gap: '10px' }}>
                  <label className="title mb-0">{t(title) || ''}</label>
                </div>
              )}
            </>
            }
          </div>
          <div className="d-flex profile-section align-items-center gap-14px w-50">
            <div className="w-100">
              <Input className="check-airwaybill relative w-100" placeholder={t('ui.menu.checkAirwaybill')} addonBefore={<Icon name="deo-search" />} onPressEnter={handleCheckAirwaybill} />
            </div>
            <div className="navbar-card d-flex align-items-center justify-content-end">
              <BalanceAmount />
              <div className="translate">
                <Popover
                  placement="bottom"
                  title={'Translate'}
                  arrowPointAtCenter
                  content={translateDropdown}
                  trigger="click"
                >
                  <div className="d-flex justify-content-center align-items-center gap-5px">
                    <Icon name="i-Globe" />
                    <span className="current-language">{i18n.language.toUpperCase()}</span>
                  </div>
                </Popover>
              </div>
              <div className="notification">
                <Popover
                  placement="bottom"
                  title="Notification"
                  arrowPointAtCenter
                  content={<NotificationComponent onOpenNotif={() => setUnreadNotification(false)} />}
                  trigger="click"
                  destroyTooltipOnHide={true}
                >
                  <a className="position-relative">
                    {unReadNotification && (
                      <div className="unread-notification"></div>
                    )}
                    <Icon name="i-Bell" />
                  </a>
                </Popover>
              </div>
              <Dropdown
                overlay={dropdown}
                placement="bottomRight"
                arrow
                trigger={['click']}
              >
                <div className="relative">
                  {relatedData?.userCompany && (
                    relatedData?.userCompany?.companyPicture ? (
                      <img className="user-account-img" src={relatedData?.userCompany?.companyPicture} alt="#" />
                    ) : (
                      <img className="user-account-img" src="assets/img/Icons/icon-avatar.svg" alt="#" />
                    )
                  )}
                  {relatedData?.userType === 'B2B' && (
                    <img src="assets/img/icon-b2b.svg" alt="#" className="badge-b2b" />
                  )}
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="mb-4 pl-2">
          {compact(props.pathName.replace(/features/, '').split('/'))?.map(((route, index) => (
            <>
              <span key={index} className="breadcrumb-route">{isFirstCharacterNumber(route) ? route : startCase(route)}</span>
              {index !== compact(props.pathName.replace(/features/, '').split('/'))?.length - 1 && <span className="mx-1 arrow-caret">&#62;</span>}
            </>
          )))}
        </div>
        {!isNil(SubMenus(relatedData)[props.pathName?.split('/')[3]]) && conditionalHideTabMenu() &&
          // show tab menu if user can access more than 1 permission (because if user can only have 1 permission on tab, it will be hidden)
          SubMenus(relatedData)[props.pathName?.split('/')[3]]?.filter(menu => can(menu?.permissions))?.length > 1 ? (
          <div className="col-12 order-2 d-flex flex-row px-0">
            <Tabs onChange={redirectTo} activeKey={activeTab}>
              {(SubMenus(store?.relatedData)[props.pathName?.split('/')[3]] || []).map((m) => (
                can(m?.permissions) && (
                  <TabPane
                    key={m.to}
                    tabKey={m.to}
                    tab={(
                      <div className="d-flex align-items-center">
                        {m.image ?
                          <img src={`assets/img/Balance/${m.image}.svg`} className={`tab-icon`} />
                          :
                          <Icon name={`${m.icon ? m.icon : "i-Circle"}`} className="mr-2 font-weight-bold" />
                        }
                        <div>{t(m.label)}</div>
                      </div>
                    )}
                  />
                )
              ))}
            </Tabs>
          </div>
        ) :
          <></>}
      </div>
    </Header>
  );
};
