import { LazyPageComponent } from '@clodeo/clodeo-ui/components/page/lazy/lazy-page.component';
import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  GuardRoute,
  ProvideAuth,
} from './components/guard-route/guard-route.component';
import { GuestRoute } from './components/guest-route/guest-route.component';

const LoginComponent = React.lazy(
  () => import('./routes/Login')
);
const NotFoundComponent = React.lazy(
  () => import('./routes/NotFound')
);
const ForbiddenAccessComponent = React.lazy(
  () => import('./routes/ForbiddenAccess')
);
const ForgotPasswordComponent = React.lazy(
  () => import('./routes/ForgotPassword')
);
const RegisterComponent = React.lazy(
  () => import('./routes/Register')
);
const RegisterPhoneComponent = React.lazy(
  () => import('./routes/RegisterPhone')
);
const RegisterActivationComponent = React.lazy(
  () => import('./routes/RegisterActivation')
);
const RegisterVerificationComponent = React.lazy(
  () => import('./routes/RegisterVerification')
);
const ResetPasswordComponent = React.lazy(
  () => import('./routes/ResetPassword')
);
const LoginIntegrationComponent = React.lazy(
  () => import('./routes/LoginIntegration')
);
const CredentialComponent = React.lazy(
  () => import('./routes/Credential')
);

import { MainRouter } from './features/main/main-router';

export function AppRouter() {
  return (
    <>
      <ProvideAuth>
        <Router>
          <Suspense fallback={<LazyPageComponent />}>
            <Switch>
              <GuardRoute path="/features">
                <MainRouter />
              </GuardRoute>
              <GuardRoute path="/" exact></GuardRoute>
              <GuestRoute path="/login" exact component={LoginComponent} />
              <GuestRoute
                path="/register-manual"
                exact
                component={RegisterComponent}
              />
              <GuestRoute
                path="/register-phone"
                exact
                component={RegisterPhoneComponent}
              />
              <GuestRoute
                path="/register/activation"
                exact
                component={RegisterActivationComponent}
              />
              <GuestRoute
                path="/register/verify"
                exact
                component={RegisterVerificationComponent}
              />
              <GuestRoute
                path="/forgot-password"
                exact
                component={ForgotPasswordComponent}
              />
              <GuestRoute
                path="/reset-password"
                exact
                component={ResetPasswordComponent}
              />
              <Route path="/login-integration" exact component={LoginIntegrationComponent} />
              <Route path="/credential" exact component={CredentialComponent} />
              <Route path="/404" component={NotFoundComponent} />
              <Route path="/403" component={ForbiddenAccessComponent} />
              <Route path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </ProvideAuth>
    </>
  );
}
