import React, { createContext, useEffect, useState, useMemo, useReducer, useLayoutEffect } from 'react';

import { Layout } from 'antd';
import { isNil } from 'lodash';
import Cookies from 'universal-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import settingRest from '@clodeo/libs/core/rest/setting';
import shipmentOrder from '@clodeo/libs/core/rest/shipmentOrder';
import { HandleService } from '@clodeo/clodeo-core/handle/handle.service';
import { MixpanelService } from '@clodeo/libs/core/mixpanel/mixpanel.service';
import { LocalStorageService } from '@clodeo/clodeo-core/local-storage/local-storage.service';
import { BalanceRest, UserRest } from '@clodeo/libs/core/rest';
import { GeneratorScriptService } from '@clodeo/libs/core/generatore-script/generator-script.service';
import i18n from '@clodeo/libs/core/translate/i18n';

import UpgradePopup from '../../UpgradePopup';
import OnBoardingPopup from '../../OnBoardingPopup';
import { MenuComponent } from '../menu/menu-layout.component';
import { SiderLayoutComponent } from '../sider/sider-layout.component';
import { HeaderLayoutComponent } from '../header/header-layout.component';
import { AddOrderComponent } from '../sider/add-order-sider-layout.component';

import useLocalData from '../../../hooks/useLocalData';
import LayoutContext from '../../../core/context/layout';
import LocalDataReducer from '../../../core/reducers/localdata';
import { AuthenticationService } from '../../../core/auth/authentication.service';

import { UserStatusType } from '../../../../global';
import { environment } from '../../../../environments/environment';

import './main-layout.component.scss';
import { EventService } from '@clodeo/libs/core/event';
import { Routes } from '../../../routes/routes';
import { AclService } from '../../../core/auth/acl.service';
import ShipdeoClosed from '../../ShipdeoClosed';

const { Content } = Layout;
const { listen } = new EventService();
const cookies = new Cookies();
const authenticationService: AuthenticationService = new AuthenticationService();
const localStorageService = new LocalStorageService();
const { getBalance } = BalanceRest(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { getRelatedData } = UserRest(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService();
const { can } = new AclService();

export const showAlertProfile = createContext<any>({});
export const MultiResiOrder = createContext(null);
export const SearchOrder = createContext(null);
export const MainLayoutComponent = (props: {
  children?: any;
}) => {

  const { getClient } = settingRest(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const { getPaymentMethods } = shipmentOrder(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  const [collapseSider, setCollapseSider] = useState<boolean>();
  const [compact, setCompact] = useState(false);
  const [isShowModalOnboarding, setIsShowModalOnboarding] = useState<boolean>(false);
  const [isShowModalShipdeoClosed, setIsShowModalShipdeoClosed] = useState<boolean>(false);
  const [multiResiActive, setMultiResiActive] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(null)

  const location = useLocation();
  const router = useHistory();
  const { dispatch: dispactLocalData } = useLocalData();

  function onCloseModalOnBoarding() {
    setIsShowModalOnboarding(false);
    cookies.remove('isFirstTimeRegistration', { path: '/' });
  }

  function bootIntercomMixpanel(data) {
    new GeneratorScriptService().bootIntercom(data);
    new MixpanelService().boot(data);
  }

  function handleDispacthRelatedData(value) {
    const status: UserStatusType = value?.status || '';
    dispactLocalData({
      type: 'update',
      name: 'relatedData',
      value,
    })

    dispactLocalData({
      type: 'update',
      name: 'isB2CVerification',
      value: false,
    })

  }

  function handleGetRelatedData() {
    const obs = getRelatedData();
    handleRequest({
      obs,
      hideError: true,
      onDone: (res) => {
        if (res) {
          bootIntercomMixpanel(res?.data || {});
          handleDispacthRelatedData(res?.data || {});

          if (res.data.isOwner && (!res.data.isPhoneUpdated || !authenticationService.user?.user?.phone)) {
            router.push('/register-phone');
          }

          if (res.data.isClosed) {
            setIsShowModalShipdeoClosed(true);
          }
        }
      },
    })
  }

  function handleGetClientData() {
    handleRequest({
      obs: getClient(),
      onDone: (res) => {
        dispactLocalData({
          type: 'update',
          name: "clientData",
          value: res.data.length ? res.data[0] : null
        });
      },
    });
  }

  function handleGetPaymentMethods() {
    handleRequest({
      obs: getPaymentMethods(),
      onDone: (res) => {
        dispactLocalData({
          type: 'update',
          name: "paymentMethodData",
          value: res.data.length ? res.data : null
        });
      },
      hideError: true
    });
  }

  function phoneValidation(phone) {
    if (!isNil(phone) && phone[0] === '0') return phone.replace('0', '+62')
    return phone
  }

  function listenScroll() {
    const mainElelemt = document.getElementById('main-layout-content');
    const heightHeader = document.getElementsByClassName('ant-layout-header')[0];
    mainElelemt.addEventListener('scroll', (event => {
      if (mainElelemt.scrollTop > (heightHeader.clientHeight / 2)) {
        heightHeader.classList.add('bg-white', 'bg-animation');
      } else {
        heightHeader.classList.remove('bg-white', 'bg-animation');
      }
    }), true);
  }

  function getDetailBalance() {
    const obs = getBalance();
    handleRequest({
      obs,
      onDone: (res) => {
        dispactLocalData({
          type: 'update',
          name: "balanceData",
          value: res.data
        });

        localStorage.setItem('phoneNumber', phoneValidation(res.data.user.phone));

        dispactLocalData({
          type: 'update',
          name: 'phoneNumber',
          value: phoneValidation(res.data.user.phone),
        });
      },
    });
  }

  useEffect(() => {
    setTimeout(() => {
      getDetailBalance();
    }, 1000);
  }, [location.pathname]);

  useEffect(() => {
    const currentUser = localStorageService.getItem('currentUser');

    if (moment(currentUser.accessTokenExpiresAt).isBefore(new Date())) {
      authenticationService.logout(() => router.push('/login?tokenExpired=true'));
    }

    const isFirstTimeRegistration = cookies.get('isFirstTimeRegistration');
    dispactLocalData({
      type: 'update',
      name: 'currentUser',
      value: currentUser,
    });

    handleGetRelatedData();
    getDetailBalance();
    handleGetClientData();
    handleGetPaymentMethods();

    if (isFirstTimeRegistration) {
      setIsShowModalOnboarding(true);
    }

    i18n.changeLanguage('id');
  }, []);

  useEffect(() => {
    listen('PORTAL:LOADRELATEDDATA', () => {
      handleGetRelatedData();
    });
  }, []);

  useLayoutEffect(() => {
    listenScroll();
  }, []);

  const [store, dispatch] = useReducer(LocalDataReducer, { siderWidth: 188, headerHeight: 100 });
  const contextValue = useMemo(() => ({ store, dispatch }), [store, dispatch]);

  return (
    <>
      <LayoutContext.Provider value={contextValue}>
        <Layout className="main-layout">
          <SiderLayoutComponent
            compact={compact}
            onCollapseChanges={setCollapseSider}
            addOrder={
              can('order.create') && <AddOrderComponent />
            }
            menu={
              <MenuComponent
                onCompactChange={setCompact}
                isCollapse={collapseSider}
                pathName={location.pathname}
              />
            }
          />
          <SearchOrder.Provider value={{ search, setSearch }}>
            <MultiResiOrder.Provider value={{ multiResiActive, setMultiResiActive }}>
              <Layout id="main-layout-content" className="main-layout-content">
                <HeaderLayoutComponent
                  pathName={location.pathname}
                />
                <Content className="px-3 py-1" style={{ marginTop: store?.headerHeight, height: `calc(100vh - ${store?.headerHeight}px)` }}>
                  <div className="mb-4 pr-3">
                    {props.children}
                  </div>
                </Content>
              </Layout>
            </MultiResiOrder.Provider>
          </SearchOrder.Provider>
        </Layout>
      </LayoutContext.Provider>

      {isShowModalOnboarding && (
        <OnBoardingPopup
          visible={isShowModalOnboarding}
          onClose={onCloseModalOnBoarding}
          onClickCompleteProfile={() => {
            setIsShowModalOnboarding(false);
            router.push(Routes.PROFILE);
          }}
        />
      )}

      {isShowModalShipdeoClosed && (
        <ShipdeoClosed
          visible={isShowModalShipdeoClosed}
          onClose={() => setIsShowModalShipdeoClosed(false)}
        />
      )}
      <UpgradePopup />
    </>
  );
};
