import { Modal as BaseModal } from 'antd';
import React from 'react';
import { ModalProps } from './modal';

import './styles.scss';

const Modal = (props: ModalProps) => (
  <BaseModal
    closeIcon={props.closeIcon || <CloseIcon />}
    centered
    width={props.width || 'fit-content'}
    maskClosable={false}
    {...props}
    className={`modal-shipdeo ${props.className}`}
    forceRender
    maskTransitionName=""
    // transitionName="none"
  >
    {props.children}
  </BaseModal>
);

const CloseIcon = () => (
  <i className="icon-deo-close"></i>
)

export {
  Modal
}
