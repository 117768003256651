import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;
let requestV2: RequestService;

export default function OrderRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1`,
    useInterceptor,
    withoutInterceptor
  );
  requestV2 = requestService.new(
    `${baseUrl['API_MAIN']}/v2`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    // v1
    create(payload) {
      return request.post('orders/batch', payload);
    },

    update(id, payload) {
      return request.put(`orders/${id}`, payload);
    },

    getOrder(id) {
      return request.get(`orders/${id}`);
    },

    getCourirOwner() {
      return request.get(`couriers/list`);
    },

    counterShippingLabel(payload) {
      return request.post(`shipping-label/counter`, payload);
    },

    findAll(params, body: any = {}) {
      return request.post('orders', body, params);
    },

    loadShippingLabelThermal(payload) {
      return request.post<any>(`shipping-label/data`, payload);
    },

    getFacet(params) {
      return request.post('orders/facet', null, { params });
    },

    getFacetCity(params) {
      return request.post('orders/city-destination', null, { params });
    },

    getDetail(payload) {
      return request.post('orders/airwaybill', payload);
    },

    import(payload) {
      return request.post('orders/import', payload);
    },

    cancelOrder(payload) {
      return request.delete(`orders/batch`, payload);
    },

    pickupOrder(payload) {
      return request.put(`orders/batch`, payload);
    },

    // v2
    cancelOrderV2(payload) {
      return requestV2.delete(`orders/batch`, payload);
    },

    getWidget(params) {
      return requestV2.post('orders/dashboard', {}, { params });
    },

    loadShippingLabel(payload) {
      return requestV2.post<any>(`shipping-label`, payload);
    },

    refreshPaymentStatus(orderId) {
      return requestV2.patch(`orders/refresh-payment-status/${orderId}`);
    }
  }
}