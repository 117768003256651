export const environment = {
  baseUrl: 'https://nx-portal-web-development.shipdeo.com',
  environmentName: 'development',
  production: false,
  ENDPOINTS: {
    API_ACCOUNT: 'https://auth-api-development.shipdeo.com',
    API_ADMIN: 'https://admin-api-development.shipdeo.com/v1',
    API_MAIN: 'https://portal-api-development.shipdeo.com',
    API_FILE: 'https://portal-api-development.shipdeo.com/v1/file/upload-image',
    API_SHIPDEO_MAIN: 'https://main-api-development.shipdeo.com/v1',
  },
  DOMAIN: {
    KLOOLA_WEB_COMPANY: 'https://app.core.clodeo.s.vitamincode.id',
    SHIPDEO_WEB_COMPANY: 'https://web.d.shipdeo.com'
  },
  CONTENTS: {
    TITLE: 'Portal Shipdeo',
    CLIENT_ID: 'shipdeo',
    COMPANY_LOGO: 'assets/img/Logo/shipdeo-logo.svg',
    SMALL_COMPANY_LOGO: 'assets/img/Logo/shipdeo.svg',
    WHITE_COMPANY_LOGO: 'assets/img/Logo/register-shipdeo-logo.svg',
    APP_COLOR: '#d0021b',
    FAVICON: 'favicon.ico'
  },
  KEYS: {
    GOOGLE_MAP: 'AIzaSyBd0AWvcQ8EIsfnIn2c9umbidHjHqebBDA',
    GOOGLE_OAUTH: '1028708956121-lho7gl5nggusn5q53asre6i0j722sqe8.apps.googleusercontent.com',
    INTERCOM_ID: 'mvs5bsu9',
    OneSignalAppID: 'beef28cc-34b6-4c4b-9d59-822505a8452c'
  },
  MIXPANEL_TOKEN: '521264b92326832dff77eb7af456e57c',
  REGISTER_SOURCES: ['kloola']
};
