import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function PromoRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/promos`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    findAll(params) {
      return request.get('redeem/list', params);
    },
    getPromo(params) {
      return request.get('', { params });
    },
    redeemPromo(data) {
      return request.post('redeem', data);
    }
  }

}
