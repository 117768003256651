import React, { StrictMode } from 'react';

import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { MixpanelService } from '@clodeo/libs/core/mixpanel/mixpanel.service';

import { App } from './app/app';
import { store } from './app/core/redux/store';
import { environment } from './environments/environment';

import { GeneratorScriptService } from 'libs/core/src/generatore-script/generator-script.service';

(async () => {
  const generatorScriptService = new GeneratorScriptService();
  await generatorScriptService.bootIntercom({ Platform: 'Shipdeo' });
  generatorScriptService.generateScript({ facebook_pixel_key: "323783306473277" }, { fb: { fbqStatus: true } });

  const mixpanelService = new MixpanelService();
  mixpanelService.boot(null);
  if (environment.production) {
    await generatorScriptService.injectGoogleTagManagerScript('G-VXTGVHHXQN');
  }

  const rootElement = document.getElementById('root');

  if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(
      <StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </StrictMode>,
      rootElement
    );
  } else {
    ReactDOM.render(
      <StrictMode>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </StrictMode>,
      rootElement
    );
  }
})();
