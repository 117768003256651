import { castArray, uniq, concat, identity, map, includes, filter } from 'lodash';

import { AuthenticationService } from '../../core/auth/authentication.service';
import { MenuService } from '../../components/layout-component/menu/menu.service';
import { Routes } from 'apps/portal/src/app/routes/routes';

const authService: AuthenticationService = new AuthenticationService;
export class AclService {
  constructor() {
    this.can = this.can.bind(this);
  }

  roles = [];
  hasRole(role) {
    return this.roles.includes(role);
  }

  attachRole(role) {
    const roles = castArray(role);
    this.roles = uniq(concat(this.roles, roles));
  }

  can(...accesses) {
    const user = <any>authService.user;
    this.roles = user?.user?.permissions || [];

    accesses = accesses.filter(identity);
    if (!accesses.length || user?.user?.isOwner) {
      return true;
    }
    return map(accesses, accessGroup => this.canByGroup(accessGroup)).filter(identity).length > 0;
  }

  flushRoles() {
    this.roles = [];
  }

  canByGroup(access) {
    const abilities = this.roles;
    const accesses = castArray(access);

    const results = accesses.map(ability => {
      return includes(abilities, ability);
    });

    const matchAbilities = results.filter(identity).length;
    // old condition
    // return matchAbilities === accesses.length;
    return matchAbilities;
  }

  checkCompanies(data = []) {
    if (!data?.length) {
      return true;
    }
    const status = []
    map(data, value => {
      if (value === 'shipdeo') {
        status.push(true);
      }
    });
    return status.length > 0;
  }

  allowedMenus(data = <any>MenuService.menus) {
    const user = <any>authService.user;
    const validMenus = [];
    filter(data, (menu) => {
      if (this.can(menu.permissions) || user?.user?.isOwner) {
        if (menu.subs) {
          const validSubs = this.allowedMenus(menu.subs);
          menu.subs = validSubs;
          menu.to = menu.subs[0].to;
        }
        if (menu.subMenu) {
          const validSubs = this.allowedMenus(menu.subMenu);
          menu.subMenu = validSubs;
          menu.to = menu?.subMenu[0]?.to;
        }
        validMenus.push(menu);
      }
    });
    return validMenus;
  }

  redirectAllowedMenu(history, forceRedirect = false) {
    const validMenus = this.allowedMenus();
    // handle for redirect if user child didn't set any permission
    // if length validMenus is lower than 3 (only shipdeo-apps and setting menu), redirect to profile
    if (validMenus.length < 3) {
      history.push(Routes.PROFILE);
    } else {
      if (validMenus[0]) {
        if (validMenus[0].subs) {
          forceRedirect ? window.location.href = validMenus[0].subs[0].to : history.push(validMenus[0].subs[0].to);
        } else if (validMenus[0].subMenu) {
          forceRedirect ? window.location.href = validMenus[0].subMenu[0].to : history.push(validMenus[0].subMenu[0].to);
        } else {
          forceRedirect ? window.location.href = validMenus[0].to : history.push(validMenus[0].to);
        }

      } else {
        history.push('/');
      }
    }
  }

}
