import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function AdjustmentRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/adjustment`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    getAdjustment(params: any) {
      return request.get<any>('list', { params });
    },

    getWidget(params: any) {
      return request.get<any>('widget', { params });
    }
  }
}
