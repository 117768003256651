import React from 'react';
import { useHistory } from 'react-router';

import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';

import { AclService } from '../../../core/auth/acl.service';
import './sider-layout.component.scss';
import { Routes } from '../../../routes/routes';

const { can } = new AclService();
export const AddOrderComponent = () => {
  const { push } = useHistory();

  return (
    <div className="d-flex justify-content-center mb-4">
      {can(['order.create']) && (
        <div className="add-order-sider d-flex align-items-center gap-11px" onClick={() => push(`${Routes.SHIPMENT_ORDER}/create`)}>
          <img src="assets/img/Icons/icon-plus.svg" alt="#" />
          <span>Buat Order</span>
        </div>
      )}
    </div>
  );
};
