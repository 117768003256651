import { createSlice } from '@reduxjs/toolkit';

export const SpinnerReducer = createSlice({
  name: 'spinner',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setIsLoading: (state, {payload}) => {
      state.isLoading = payload;
    }
  },
})

export const { setIsLoading } = SpinnerReducer.actions

export default SpinnerReducer.reducer;
