import React from 'react';

import { Modal } from '../Base/Modal';

interface IShipdeoClosedProps {
  onClose: () => void;
  visible: boolean;
}

export default function ShipdeoClosed(props: IShipdeoClosedProps) {
  const { onClose, visible } = props;
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Informasi Penting"
      width={600}
      footer={false}
    >
      <div className="bg-white p-3 rounded-20px">
        <div className="d-flex flex-column gap-20px">
          <h6 className="m-0 fw-600">Halo, Shipfellas!</h6>
          <p className="mb-0 text-justify">Kami ingin menginformasikan bahwa layanan Shipdeo akan <strong>berhenti sementara</strong> mulai tanggal <strong>31 Oktober 2024 23:59</strong> hingga waktu yang tidak ditentukan. Meskipun demikian, sistem kami akan tetap memproses <strong>request pickup</strong> hingga tanggal <strong>15 Oktober 2024 23:59</strong>, dan kami akan memaksimalkan penyelesaian proses pengiriman yang sedang berjalan serta memastikan <strong>pencairan dana COD</strong> Anda tetap terlaksana.</p>
          <p className="mb-0">Terima kasih atas dukungan dan kepercayaan yang terus Anda berikan kepada Shipdeo. Kami berharap dapat segera kembali melayani Anda lebih baik lagi.</p>
          <div>
            <p className="mb-0">Salam hangat,</p>
            <p className="mb-0">Tim Shipdeo</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}