import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function UserBranchRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(`${baseUrl['API_MAIN']}/v1/branch`, useInterceptor, withoutInterceptor);

  return {
    getAllBranch(params) {
      return request.get<any>('', params);
    },

    getBranchById(id: string) {
      return request.get<any>(id);
    },

    createBranch(body: any) {
      return request.post('create', body);
    },

    updateBranch(body: any, id: string) {
      return request.put(`${id}/update`, body);
    },

    deleteBranch(id: string) {
      return request.delete(`${id}/delete`);
    },

    importBranch(payload) {
      return request.post<any>(`imports`, payload);
    }
  }

}
