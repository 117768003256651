import { has, get } from 'lodash';
import { throwError } from 'rxjs';
import { AxiosInstance, AxiosStatic } from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { tap, switchMap, catchError } from 'rxjs/operators';

import { TemporaryFixService } from '@clodeo/clodeo-core/common/temporary-fix.service';
import { ManageUserRest, TokenRest } from '@clodeo/libs/core/rest';

import { MixpanelService } from '@clodeo/libs/core/mixpanel/mixpanel.service';
import { GeneratorScriptService } from '@clodeo/libs/core/generatore-script/generator-script.service';

import { environment } from '../../../environments/environment';

const generatorScriptService: GeneratorScriptService = new GeneratorScriptService();
const temporaryService: TemporaryFixService = new TemporaryFixService();
const mixpanelService: MixpanelService = new MixpanelService();
const { getToken, getTokenByGoogle, verifyEmail, resetPassword, refreshToken: generateToken, verifyStatus } = TokenRest(environment.ENDPOINTS);
const { userOtpVerify } = ManageUserRest(environment.ENDPOINTS, false);
const cookies = new Cookies();
export class MUser {
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
  user: {
    id: string;
    fullName: string;
    email: string;
    isVerified: boolean;
    hasCompany: boolean;
    isOwner: boolean;
    ownerId: string;
    companyId: string;
    permissions: string[];
    isShowOnlyBranch: boolean;
    isShowOnlyDepartement: boolean;
    phone: string;
  };
  access_token: string;
  isLoginByGoogle: boolean;
  isPhoneUpdated?: boolean;
}

let data: MUser;

// just for experimental, so this function need to improve
const refreshToken = () => {
  const payload = {
    grant_type: 'refresh_token',
    refresh_token: data.refreshToken,
    client_secret: 'VpFraMKSgptTeZUz',
    // client_id: 'koperasi-sicepat-admin-web'
  };
  return getToken(payload).pipe(
    tap((loginData: any) => {
      data = loginData;
      localStorage.setItem('currentUser', JSON.stringify(loginData));
      cookies.set('currentUser', loginData?.user?.fullName || '', {
        path: '/',
        domain: '.shipdeo.com',
      });
      window.location.reload();
    })
  );
};

export class AuthenticationService {
  constructor() {
    const currentUser = localStorage.getItem('currentUser');

    // checking if user manually insert the localstorage
    let user;

    try {
      user = JSON.parse(currentUser);
    } catch (e) {
      this.logout();
    }

    if (user && has(user, 'access_token')) {
      this.user = user as any;
    } else {
      this.user = null;
    }
  }

  set user(newData: MUser) {
    data = newData;
  }

  get user() {
    return data;
  }

  axiosInterceptors(
    axios: AxiosInstance | AxiosStatic,
    interceptRequest = true,
    interceptResponse = true
  ) {
    axios.interceptors.request.use((request) => {
      request.headers.common['Timezone-Offset'] =
        get(data, 'timezone_offset') || new Date().getTimezoneOffset();
      request.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
      request.headers.common['Accept'] = 'application/json, text/plain, */*';
      request.headers.common['Accept-Language'] = 'id-ID';

      return request;
    });

    if (interceptRequest) {
      axios.interceptors.request.use((request) => {
        if (data) {
          request.headers.common[
            'Authorization'
          ] = `Bearer ${data.access_token}`;
        }
        return request;
      });
    }

    if (interceptResponse) {
      axios.interceptors.response.use(
        (response) => {
          temporaryService.adressObjectNullHandler(response);

          return response;
        },
        async (error) => {
          const response = error.response;
          const errorCode = get(response, 'data.code');

          if (
            response &&
            errorCode === 401 &&
            response.config &&
            response.config.headers &&
            response.config.headers['Authorization']
          ) {
            return refreshToken()
              .pipe(
                switchMap(function (accessToken) {
                  data = accessToken as any;
                  response.config.headers[
                    'Authorization'
                  ] = `Bearer ${accessToken}`;
                  return axios.request(response.config);
                }),
                catchError((err) => {
                  data = null;
                  this.removeDataLocal();
                  if (location.pathname !== '/register-phone') {
                    window.location.href = '/login?tokenExpired=true';
                  }
                  return throwError(err);
                })
              )
              .toPromise();
          }
          throw error;
        }
      );
    }
  }

  login(payload: any) {
    const newPayload = {
      ...payload,
      grant_type: 'password',
    };

    return getToken(newPayload).pipe(
      tap((loginData: any) => {
        loginData.isLoginByGoogle = false;
        loginData.user.isShowNewViewOrder = true;
        this.user = loginData;
        localStorage.setItem('currentUser', JSON.stringify(loginData));
        cookies.set('currentUser', loginData?.user?.fullName || '', {
          path: '/',
          domain: '.shipdeo.com',
        });
      })
    );
  }

  loginByGoogle(payload: any) {
    return getTokenByGoogle(payload).pipe(
      tap((loginData: any) => {
        loginData.isLoginByGoogle = true;
        loginData.user.isShowNewViewOrder = true;
        this.user = loginData;
        localStorage.setItem('currentUser', JSON.stringify(loginData));

        if (loginData?.isNewAccount) {
          cookies.set('isFirstTimeRegistration', true, {
            path: '/',
            expires: moment().add(1, 'days').toDate(),
          });
        }

        cookies.set('currentUser', loginData?.user?.fullName || '', {
          path: '/',
          domain: '.shipdeo.com',
        });
      })
    );
  }

  activateUser(id) {
    return verifyStatus(id);
  }

  verifyEmail(payload) {
    return verifyEmail(payload);
  }

  resetPassword(id, payload) {
    return resetPassword(id, payload);
  }

  refreshToken(payload) {
    return generateToken(payload);
  }

  checkBilling(id) {
    const condition = 1;
    return id === condition ? true : false;
  }

  removeDataLocal() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('dashboardShipment');
    localStorage.removeItem('dashboardDelivery');
    localStorage.removeItem('dashboardDeliveryCOD');
    localStorage.removeItem('topRateDelivered');
    localStorage.removeItem('topRateReturned');
    localStorage.removeItem('modeDetail');
    localStorage.removeItem('shipmentOrder');
    localStorage.removeItem('dashboardShipmentCOD');
    cookies.remove('currentUser', { path: '/', domain: '.shipdeo.com' });
  }

  logout(afterLogout?: () => void) {
    generatorScriptService.shutdown();
    data = null;
    this.removeDataLocal();
    mixpanelService.logout();
    afterLogout ? afterLogout() : window.location.href = '/login';
  }

  verifyOtp(payload) {
    return userOtpVerify(payload)
      .pipe(
        tap(({ data }: any) => {
          const newData: MUser = {
            access_token: data.accessToken || '',
            accessToken: data.accessToken || '',
            user: data.user,
            refreshToken: data.access_token,
            accessTokenExpiresAt: '',
            isLoginByGoogle: false,
            refreshTokenExpiresAt: ',',
          };
          this.user = newData;
          localStorage.setItem('currentUser', JSON.stringify(newData));

          cookies.set('isFirstTimeRegistration', true, {
            path: '/',
            expires: moment().add(1, 'days').toDate(),
          });

        })
      );
  }
}
