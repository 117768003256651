import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function ReturningRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v2/shipping-return`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    findAll(params, body: any = {}) {
      return request.post('query', body, params);
    },

    createEvent(data) {
      return request.post('', data);
    }
  }

}
