import { isShowVerificationPopup } from "../components/VerificationPopup/utils";

export const Routes = {
  DASHBAORD: '/features/dashboard',
  SHIPMENT_ORDER: '/features/shipment/order',
  RETURN_POTENSIAL: '/features/shipment/return/potensial',
  RETURNING: '/features/shipment/return/returning',
  RECEIPT_RETURNED: '/features/shipment/return/receipt',
  CONTACT: '/features/contact',
  CHECK_SHIPPING_COST: '/features/shipment/shipping-cost',
  CHECK_AIRWAYBILL: '/features/shipment/check-airwaybill',
  INTEGRATION_API: '/features/shipdeo-apps/integration',
  BRANCH: '/features/setting/sender/branch',
  DEPARTMENT: '/features/setting/sender/department',
  USER: '/features/setting/user',
  SHIPPING_LABEL: '/features/setting/shipping-label',
  EXPEDITION: '/features/setting/expedition',
  INVOICE: '/features/invoice',
  VERIFICATION: '/features/user/setting/verification',
  PROFILE: '/features/user/setting/profile',
  COMPANY: '/features/user/setting/company-info',
  CONFIGURATION: '/features/setting/configuration',
  BANK: '/features/user/setting/bank-info',
  BALANCE: '/features/finance/balance/balance',
  TOPUP: '/features/finance/balance/topup',
  REPORT: '/features/report',
  WITHDRAW: '/features/finance/balance/withdraw',
  FORBIDDEN_PAGE: '/403',

  // hidden or unusable set here
  PAYMENT: '/features/finance/payment',
  ADJUSTMENT: '/features/finance/adjustment',
};

const SubMenuBalance = [
  {
    id: 'balance',
    label: 'ui.menu.balance',
    to: Routes.BALANCE,
    permissions: ['balance.balance_history']
  },
  {
    id: 'topup',
    label: 'ui.menu.topup',
    to: Routes.TOPUP,
    permissions: ['balance.topup']
  },
  {
    id: 'withdraw',
    label: 'ui.menu.withdraw',
    to: Routes.WITHDRAW,
    permissions: ['balance.withdraw']
  },
];

const SubMenuReturn = (relatedData: any) => {
  const routes = [
    {
      id: 'potensial',
      label: 'ui.menu.returnPotensial',
      to: Routes.RETURN_POTENSIAL,
      permissions: ['return_management.potential_return']
    },
    {
      id: 'returning',
      label: 'ui.menu.returning',
      to: Routes.RETURNING,
      permissions: ['return_management.potential_return']
    },
    {
      id: 'receipt',
      label: 'ui.menu.returnReceived',
      to: Routes.RECEIPT_RETURNED,
      permissions: ['return_management.return_accepted']
    },
  ];

  // if (relatedData?.userCompany?.partnerId !== 'R4BJr') {
  //   routes.splice(1, 1);
  // }

  return routes;
};

const SubMenuBranch = [
  {
    id: 'branch',
    label: 'Branch',
    to: Routes.BRANCH,
    permissions: ['shipping_address.branch']
  },
  {
    id: 'department',
    label: 'Department',
    to: Routes.DEPARTMENT,
    permissions: ['shipping_address.department']
  },
];

const SubMenuProfile = (relatedData: any) => {
  let subMenu = [
    {
      id: 'profie',
      icon: 'i-UserCircle',
      label: 'ui.menu.profile',
      to: Routes.PROFILE,
    },
    {
      id: 'verification',
      icon: 'i-CheckCircle',
      label: 'ui.menu.verification',
      to: Routes.VERIFICATION,
    },
    {
      id: 'company',
      icon: 'i-Buildings',
      label: 'ui.menu.company',
      to: Routes.COMPANY,
    },
    {
      id: 'bank',
      icon: 'i-Bank',
      label: 'ui.menu.bank',
      to: Routes.BANK,
    }
  ];

  if (!isShowVerificationPopup(relatedData)) {
    subMenu = subMenu.filter(sub => sub.id !== 'verification');
  }

  return subMenu;
};

export const SubMenus = (relatedData: any) => {
  return {
    balance: SubMenuBalance,
    return: SubMenuReturn(relatedData),
    sender: SubMenuBranch,
    setting: SubMenuProfile(relatedData)
  };
};