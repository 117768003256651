import { createContext } from 'react';

import { ILocalDataContext } from './';

export declare interface ILayoutContext extends ILocalDataContext {
  store: {
    siderWidth?: string;
    headerWidht?: string;
  };
}

const LayoutContext = createContext({} as ILayoutContext);

export default LayoutContext;
