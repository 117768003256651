import branch from './branch.json';
import dashboard from './dashboard.json';
import finance from './finance.json';
import integration from './integration.json';
import promo from './promo.json';
import returnManagement from './return-management.json';
import returning from './returning.json';
import reportShipment from './reportShipment.json';
import shipmentOrder from './shipmentOrder.json';
import widget from './widget.json';
import trackingAirwaybill from './TrackingAirwaybill.json';

export default {
  notification: {
    success: 'Success',
    error: 'Error',
    failed: 'Failed',
    contact: {
      success: {
        create: 'Create New Contact Success',
        update: 'Contact Has Been Updated',
        delete: 'Contact Has Been Deleted',
      },
      failed: {
        create: '',
        update: '',
        detele: 'Contact failed to delete',
      },
      confirmation: {
        title: 'Confirmation',
        message: 'Are you sure to delete contact with Name {{name}}?',
        action: {
          submit: 'Ok',
          cancel: 'Cancel',
        },
      },
    },
    maps: {
      zipCodeError:
        'The Location you choose does not include the District area you entered!',
      setLocation: {
        success: 'Location set successfully!',
        failed: 'Failed set Location!',
      },
    },
    user: {
      userDeleted: 'User deleted!',
      userDeleteError: 'Failed to delete user',
      userCreated: 'User created!',
      userUpdated: 'User updated!',
      branch: {
        branchCreated: 'Branch created!',
        branchUpdated: 'Branch updated!',
        branchDeleted: 'Branch deleted!',
        branchDeleteError: 'Failed to delete branch',
      },
      department: {
        departmentCreated: 'Department created!',
        departmentUpdated: 'Department updated!',
        departmentDeleted: 'Department deleted!',
        departmentDelete_error: 'Failed to delete department',
      },
      deleteConfirm: 'Are you sure want to delete the',
      map: {
        zipCodeError: 'Codepost location is different with map',
        zipCodeSuccess: 'Codepost location is same with map',
      },
    },
    upload: {
      success: 'Uploaded successfully!',
      failed: 'Failed to upload!',
      wrong: 'Wrong file type!',
      onlyImage: 'Only image file is allowed!',
    },
    errorForm: 'Please fill all the required fields!',
    successSendData: 'Data has been sent successfully!',
    updatedWithVariable: '{{name}} has been updated!',
  },
  ui: {
    action: {
      hide: 'Hide',
      show: 'Show'
    },
    field: {
      message: '{{field}} is required',
    },
    filter: {
      date: {
        end: 'End Date',
        start: 'Start Date'
      },
      monthly: 'Monthly',
      search: 'Search',
      weekly: 'Weekly'
    },
    adjustment: {
      list: {
        column: {
          adjustmentNumber: 'Adjustment Number',
          amount: 'Amount',
          courierService: 'Courier Service',
          dueDate: 'Due Date',
          issueDate: 'Issue Date',
          notes: 'Notes',
          receiptNumber: 'Receip Number',
          type: 'Type',
        },
        title: 'Adjustment List',
      },
    },
    order: {
      list: {
        orderDate: 'Order',
        reqPickupDate: 'Req Pickup',
        customer: 'Customer',
        shippingAddress: 'Shipping Address',
        CodPrice: 'COD Price',
        priceOfGoods: 'Price of goods',
        orderNumber: 'Order Number',
        trackNumber: 'No. Track',
      },
      total: 'Total Order'
    },
    invoice: {
      list: {
        column: {
          invoiceDate: 'Invoice Date',
          invoiceDueDate: 'Due Date',
          invoiceId: 'Invoice ID',
          numberId: 'Number ID',
          orderAmount: 'Order Amount',
          paymentDate: 'Payment Date',
          status: 'Status',
          totalBill: 'Total Bill',
        },
        title: 'Invoice List',
      },
      widget: {
        status: {
          '1': 'Before Due Date',
          '2': 'Paid',
          '3': 'Payment Confirmation',
          '4': 'Unpaid',
          '5': 'Due Date',
        },
      },
    },
    invoiceDetail: {
      bill: {
        billDueDate: 'Bill Due Date',
        orderAmount: 'Order Amount',
        title: 'Bill',
        totalBill: 'Total Bill',
      },
      entity: {
        actualCharge: 'Actual Charge',
        actualWeight: 'Actual Weight',
        airwaybill: 'Airwaybill',
        courier: 'Courier',
        deliveredDate: 'Delivered Date',
        discountCharge: 'Discount Charge',
        insurance: 'Insurance',
        receiverAddress: 'Receiver Address',
        totalBill: 'Total Bill',
      },
      evidenceOfTransfer: 'Evidence of Transfer',
      filter: {
        courier: 'Choose Courier',
      },
      invoice: {
        date: 'Date',
        no: 'No. Invoice',
        paymentId: 'Payment ID',
        sellerName: 'Seller Name',
        status: {
          alreadyPaidStatus: 'Paid Amount',
          expiredInvoice: 'Expired Invoice',
          onReviewStatus: 'Waiting Confirmation',
          other: 'Other',
          partiallyPaid: 'Partially Paid',
          unpaidStatus: 'Unpaid Status',
          voidStatus: 'Void Status',
          waitingForPayment: 'Waiting For Payment',
        },
        title: 'Invoice Detail',
      },
      payBill: 'Pay Bill',
      payment: {
        title: 'Payment',
      },
      status: {
        dueDate: 'Due date',
        invoice: 'Invoice Status',
        paymentDate: 'Payment Date',
        title: 'Status',
      },
      title: 'Invoice Detail',
      uploadEvidenceOfTransfer: 'Upload Evidence of Transfer',
    },
    menu: {
      balance: 'Balance',
      bank: 'Bank',
      bill: 'Invoice',
      branch: 'Sender Address / Branch',
      claim: 'Claim',
      checkAirwaybill: 'Check Airwaybill',
      company: 'Company',
      companyBank: 'Company & Bank',
      configuration: 'Configuration',
      customImportOrder: 'Custom Template Import Order',
      dashboard: 'Dashboard',
      department: 'Department',
      expedition: 'Expedition',
      integration: 'Integrasi API',
      invoice: 'Invoice',
      monitoring: 'Monitoring',
      order: 'Order',
      newOrder: 'New Order',
      profile: 'Profile',
      setting: 'Setting',
      contact: 'Contact',
      finance: 'Finance',
      permission: 'Permissions',
      promo: 'Promo',
      report: 'Report',
      reportCity: 'Report by City',
      reportCourier: 'Report by Courier',
      reportShipmentCOD: 'Report Shipment COD',
      reportStatus: 'Report by Status',
      return: 'Return Management',
      returning: 'Returning',
      returnManagement: 'Return',
      returnPotensial: 'Return Potensial',
      returnReceived: 'Receipt Return',
      shipdeoApps: 'Shipdeo Apps',
      shipment: 'Shipment',
      shippinglabel: 'Shipping Label',
      shippingCost: 'Shipping Cost',
      topup: 'Top Up',
      user: 'User',
      trackingAirwaybill: 'Tracking Airwaybill',
      verification: 'Verification',
      withdraw: 'Withdraw'
    },
    entity: {
      accessRoles: 'Access Roles',
      name: 'Fullname',
      phone: 'Phone Number',
      address: 'Address',
      email: 'Email',
      company: 'Company Name',
      shipperName: 'Shipper Name',
      npwp: 'Taxpayer Identification Number',
      note: 'Note',
      subdistrict: 'District',
      city: 'City',
      province: 'Province',
      postcode: 'Postal Code',
      latitude: 'Latitude',
      longitude: 'Longitude',
      password: 'Password',
      fax: 'Fax',
      website: 'Website',
      branch: 'Branch',
      branchName: 'Branch Name',
      department: 'Department',
      code: 'Code',
      coordinat: 'Coordinate',
      personalData: 'Personal Data',
      banking: 'Banking Information',
      companyInfo: 'Company Info',
      identityNumber: 'No. Identity',
      identityName: 'Name Identity',
      bankName: 'Bank Name',
      accountOwnerName: 'Account Owner Name',
      accountNumber: 'Account Number',
      verified: 'Verified',
      rejected: 'Rejected',
      pending: 'Pending',
      sender: 'Sender',
      senderName: 'Sender Name',
      receiver: 'Receiver',
      receiverName: 'Receiver Name',
      link: 'Link',
      action: {
        close: 'Close',
        save: 'Save',
        cancel: 'Cancel',
        upload: 'Upload',
        delete: 'Delete',
      },
      placeholder: {
        address: 'Fill Address',
        company: 'Fill Company Name',
        district: 'Find District..',
        email: 'Fill Email',
        name: 'Fill Name',
        note: 'Fill Note',
        postalCode: 'Code'
      },
    },
    maps: {
      card: {
        locationDetail: 'Detail Location',
        address: {
          subdistrict: 'District',
          city: 'City',
          province: 'Province',
          postcode: 'Postal Code',
        },
        action: {
          setLocation: 'Set Location',
        },
      },
    },
    contact: {
      maps: {
        showMap: 'Show Maps',
        closeMap: 'Close Maps',
        chooseCoordinate: 'Pick Coordinate',
        changeCoordinate: 'Change Coordinate',
        chooseLocation: 'Set Location',
      },
      action: {
        add: 'Add Contact',
        edit: 'Edit Contact',
        showLocation: 'Show Location'
      },
      detail: {
        addressInfo: 'Address Information',
        generalInfo: 'General Information',
        locationInfo: 'Location Information',
        title: 'Detail Contact',
      },
    },
    payment: {
      list: {
        column: {
          issueDate: 'Issue Date',
          payment: 'Payment',
          paymentId: 'Payment ID',
          paymentNumber: 'Payment Number',
          paymentType: 'Payment Type',
          restOfBill: 'Rest Of Bill',
          tenantName: 'Tenant Name',
          totalBill: 'Total Bill',
          totalInvoice: 'Total Invoice',
        },
        title: 'Payment List',
      },
      paymentDetail: {
        attachment: 'Attachment',
        billValue: 'Bill Value',
        invoice: 'Invoice',
        notes: 'Notes',
        paymentDate: 'Payment Date',
        paymentNumber: 'Payment Number',
        phoneNumber: 'Phone Number',
        restOfBill: 'Rest Of Bill',
        tenantId: 'Tenant ID',
        tenantName: 'Tenant Name',
        title: 'Payment Detail',
        totalInvoice: 'Total Invoice',
        totalInvoiceAmount: 'Total Invoice Amount',
        totalOrder: 'Total Order',
        totalPayment: 'Total Payment',
      },
    },
    setting: {
      profile: {
        addPhoneNumber: {
          title1: 'Add Phone Number',
          title2: 'Get OTP',
          title3: 'Verification OTP',
          description1: 'Please add your mobile number, then we will send a message containing the OTP code to that number',
          description2: 'We will send an OTP code to your mobile number. Choose one of the methods below',
          description3: 'We sent the OTP code via Whatsapp to {{phone}}. Enter the code in the column below'
        },
        companyInfo: {
          title: 'Company Info',
          upload: 'Upload',
        },
        contactInfo: {
          title: 'User Profile',
        },
        referralCode: {
          title: 'Referral Code',
          generate: 'Generate',
          warning: 'Your account does not have a referral code yet. Please Generate first!',
          info: 'Use the referral code to invite your friends to join and get other benefits from the Shipdeo Referral program.',
        },
        bankAccount: {
          title: 'Bank Account',
          bankName: 'Bank Name',
          accountName: 'Account Name',
          accountNumber: 'Account Number',
          bankAddress: 'Bank Address',
          branch: 'Branch',
        },
        authorization: {
          title: 'Authorization',
        },
        changePhoneNumber: {
          title1: 'Change Phone Number',
          title2: 'Mobile number and OTP',
          title3: 'Verifikasi OTP',
          description1:
            'Please edit your mobile number, then we will send a message containing the OTP code to that number',
          description2:
            'We will send an OTP code to your mobile number. Choose one of the methods below',
          description3:
            'We sent the OTP code via Whatsapp to {{phone}}. Enter the code in the column below',
        },
        changeEmail: {
          title1: 'Change Email',
          title2: 'OTP Verification',
          description1:
            'Please edit your email, then we will send a message containing the OTP code to that email',
          description2:
            'We send the OTP code via Email to {{email}}. Enter the code in the field below',
        },
      },
      apiKey: {
        shopName: {
          label: 'Shop Name',
          placeholder: 'Enter name'
        },
        couriers: {
          label: 'Couriers',
          placeholder: 'Select preferred courier'
        },
        service: 'Service',
        platform: 'Platform'
      },
      shippingLabel: {
        showItemList: 'Show Item List',
        showOrderNumber: 'Show Order Number',
        showOrderNumberBarcode: 'Show Order Number Barcode',
        showSenderAddress: 'Show Sender Address',
        showShippingCharge: 'Show Shipping Charge',
        showAllItems: 'Show All Item'
      },
      expedition: {
        error: 'Fail, Please activate one of the courier before change',
        success: 'Successfully update expedition'
      },
      verification: {
        description: 'To maintain security and prevent unauthorized actions, we require several documents as part of the verification process. The documents we need include a photo of your ID Card (Identity Card) and also your selfie photo. By using these documents, we can ensure that you are an authorized user. Thank you for your cooperation and understanding.',
        title: 'Document Verification',
        upload: 'Complete the Document'
      }
    },
    user: {
      accessRoles: {
        placeholder: 'Select Permissions',
        title: 'Clone permissions from'
      },
      action: {
        confirm: 'Yes, Leave'
      },
      cancelAdd: {
        description: 'By leaving the filling of the form, the filled information will disappear.',
        title: 'CANCEL ADD USER?'
      },
      userCode: 'User Code',
      userName: 'User Name',
      userDetail: 'User Detail',
      passwordGenerate: 'Generate Password',
      addUser: 'Add User',
      updateUser: 'Update User',
      branch: {
        branchName: 'Branch Name',
        branchCode: 'Branch Code',
        branchDetail: 'Branch Detail',
        districtName: 'District Name',
        selectBranch: 'Select Branch',
        addBranch: 'Add Branch',
        updateBranch: 'Update Branch',
        hasLocation: 'Has Been Set',
        hasNotLocation: 'Not set',
      },
      department: {
        departmentName: 'Department Name',
        departmentCode: 'Department Code',
        selectDepartment: 'Select Department',
        addDepartment: 'Add Department',
        updateDepartment: 'Update Department',
      },
      confirmation: 'Confirmation',
      required: 'This field is required',
      requiredEmail: 'Email format is not correct',
      lowercaseEmail: 'Use only lowercase letters',
      cancel: 'Cancel',
      save: 'Save',
      map: {
        showMap: 'Show Maps',
        closeMap: 'Close Maps',
        detail: 'Detail Location',
        src: 'Search Location',
        chooseCoordinate: 'Pick Coordinate',
        changeLocation: 'Change Location',
        chooseLocation: 'Set Location'
      },
      permission: {
        balance: 'Balance',
        dashboard: 'Dashboard',
        order: 'Order',
        finance: 'Finance',
        contact: 'Contact',
        information: 'Information',
        paymentMethod: 'Payment Method',
        status: {
          active: 'Active',
          inactive: 'Inactive'
        }
      }
    },
    upload: {
      identity: 'Upload Photo of ID card',
      identitySelfie: 'Upload Selfie Photo with ID card',
      bank: 'Upload Photo of Account Book',
      description:
        'The image format is .jpg .jpeg .png and a minimum size of 300 x 300px (For optimal images use a minimum size of 700 x 700 px).',
    },
    button: {
      action: 'Action',
      back: 'Back',
      download: 'Download',
      export: 'Export',
      import: 'Import',
      save: 'Save',
      saveChanges: 'Save Changes',
      cancel: 'Cancel',
      upload: 'Upload',
      delete: 'Delete',
      edit: 'Edit',
      editWithVariable: 'Edit {{name}}',
      addWithVariable: 'Add {{name}}',
      arrangeDelivery: 'Arrange Delivery',
      pay: 'Pay',
      next: 'Next',
      withdrawTnC2: 'Terms of withdrawal of the balance.'
    },
    balance: {
      paymentMethod: 'Payment Method',
      waitingPayment: 'Waiting Payment',
      nominalTopup: 'Amount to Top Up?',
      payNow: 'Pay Now',
      adminReview: 'Admin Review',
      success: 'Success',
      canceled: 'Canceled',
      history: 'History',
      withdrawCondition: 'Withdraw Condition',
      agree: 'Agree',
      all: 'Balance',
      pending: 'Withdrawal Process',
      readyToWithdraw: 'Withdrawal Delivery',
      onProccess: 'Already Collected',
      minimumWithdraw: 'Minimum withdrawal Rp 10.000',
      balance: 'Balance',
      withdrawAmount: 'Withdraw Amount',
      withdrawAll: 'Withdraw All',
      amount: 'Amount',
      destinationAccount: 'Destination Account',
      withdrawTnC1: 'By clicking the Withdraw button, you have agreed to the',
      withdrawTnC2: 'Terms of withdrawal of the balance.',
      balanceAccount: 'Need to Verify Account to showing your Number Account Bank',
      tooltipAll: 'The amount of funds you have in Balance Shipdeo',
      tooltipDebit: 'The amount of funds that are in the process of disbursing to the account',
      tooltipAvailable: 'Your COD funds are still in the delivery process',
      tooltipCredit: 'Your COD funds have entered the balance',
      successMessage: 'Successfully Top Up your Balance!',
      status: {
        pending: 'Pending',
        reviewAdmin: 'Review Admin',
        waitingConfirm: 'Waiting Confirmation',
        paid: 'Paid',
        void: 'Void',
        failed: 'Failed',
        rejected: 'Rejected',
        approved: 'Approved',
        done: 'Done',
        review: 'Review Admin'
      },
    },
  },
  tables: {
    head: {
      attachment: 'Attachment',
      city: 'City',
      date: 'Date',
      disbursementDate: 'Disbursement Date',
      nominal: 'Nominal',
      nominalRequest: 'Nominal Request',
      paymentMethod: 'Payment Method',
      timeRequest: 'Time Request',
      totalBalance: 'Total Balance',
      source: 'Source',
      note: 'Note',
      type: 'Type',
      createDate: 'Create Date',
      tenantId: 'Tenant ID',
      orderNumber: 'Order Number',
      itemName: 'Items Name',
      description: 'Description',
      deliveryNote: 'Delivery Note',
      courier: 'Courier',
      service: 'Service',
      cod: 'COD',
      airwaybill: 'Airwaybill',
      sender: 'Sender',
      senderPhone: 'Sender Phone',
      origin: 'Origin',
      originAddress: 'Origin Address',
      originDistrict: 'Origin District',
      originCity: 'Origin City',
      originProvince: 'Origin Province',
      receiver: 'Receiver',
      receiverPhone: 'Receiver Phone',
      destination: 'Destination',
      destinationAddress: 'Destination Address',
      destinationDistrict: 'Destination District',
      destinationCity: 'Destination City',
      destinationProvince: 'Destination Province',
      status: 'Status',
      statusNotes: 'Status Notes',
      courierStatus: 'Courier Status',
      courierPickedDate: 'Courier Picked Date',
      deliveredDate: 'Delivered Date',
      shippingCharge: 'Shipping Charge',
      codValue: 'COD Value',
      discount: 'Discount',
      subtotal: 'Subtotal',
      total: 'Total',
      branch: 'Branch',
      reference: 'Reference',
      departement: 'Departement',
      orderDate: 'ORDER DATE',
      orderType: 'TYPE ORDER',
      resiNumber: 'RESI NUMBER',
      shipdeoStatus: 'SHIPDEO STATUS',
      shippingCost: 'SHIPPING COST',
      shippinglabel: 'Print Shipping Label',
      codornot: 'COD/Non-COD',
      totalCod: 'TOTAL COD',
      partnerId: 'Partner ID',
      senderEmail: 'Sender Email',
      billingType: 'Billing Type',
      goodsValue: 'Goods Value',
      codFeePercent: 'COD Fee (%)',
      codFeeValue: 'COD Fee (Rp)',
      weight: 'Weight',
      length: 'Length',
      height: 'Height',
      width: 'Width',
      discountShippingChargePercent: 'Diskon Shipping Charge (%)',
      discountShippingChargeValue: 'Diskon Shipping Charge (Rp)',
      insurance: 'Insurance',
      woodPacking: 'Packing Kayu',
      estimasiPencairan: 'Estimasi Pencairan',
      estimasiTagihan: 'Estimasi Tagihan',
      actualWeight: 'Actual Weight (Kg)',
      actualShippingChargeAndInsurance: 'Actual Shipping Charge + Insurance',
      codSubTotal: 'COD Subtotal',
    },
  },
  validator: {
    required: 'This field is required',
    min: 'Fill {{name}} min {{min}} characters',
    requiredWithVariable: '{{name}} is required',
    requiredImage: 'This field is required',
    email: 'Email format is not correct',
    invalid: '{{name}} is not valid',
    url: 'URL is invalid'
  },
  dashboard,
  finance,
  integration,
  promo,
  reportShipment,
  returning,
  returnManagement,
  shipmentOrder,
  widget,
  trackingAirwaybill,
  ...branch
}
