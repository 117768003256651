import React from 'react';

import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';

import UpgradeStatusComponent from '../upgrade-status/upgrade-status.component';

import useLocalData from '../../hooks/useLocalData';

export default function UpgradePopup() {
  const { store: { showUpgradePopup }, dispatch } = useLocalData();

  return (
    <Modal
      visible={showUpgradePopup}
      footer={false}
      onCancel={() => {
        dispatch({
          type: 'update',
          name: 'showUpgradePopup',
          value: false
        })
      }}
      title=""
    >
      <UpgradeStatusComponent />
    </Modal>
  )
}
