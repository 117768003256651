
import { of as observableOf, zip as observableZip, Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
// import { AutoComplete } from 'primeng/components/autocomplete/autocomplete';
// import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
// import * as CryptoJS from 'crypto-js';

export class CommonService {
  static getInitialDisplayName(str: string): string {
    let initialName: string;
    str = _.words(str).join(' ');

    if (str.split(' ').length > 1) {
      initialName = str.split(' ')[0].split('')[0] + str.split(' ')[1].split('')[0];
    } else if (str.split(' ').length === 1) {
      initialName = str.split(' ')[0].split('')[0];
    }
    return _.upperCase(initialName).replace(/\s/g, '');
  }

  static sha256(message) {
    return '';
    // return CryptoJS.SHA256(message).toString();
  }

  static convertStringToRGBColorCode(str: string, defaultColorCode = [0, 0, 0]): string {

    const rgbToHex = function (rgb) {
      let hex: string = Number(rgb).toString(16);
      if (hex.length < 2) {
        hex = '0' + hex;
      }
      return hex;
    };

    let r: number = defaultColorCode[0];
    let g: number = defaultColorCode[1];
    let b: number = defaultColorCode[2];

    try {
      let result: any = '';

      const value = str.split('');

      // Filter non hex values
      for (let i = 0; i < value.length; i++) {
        let val: any = value[i];

        if (!/^[0-9A-F]{1}$/i.test(val)) {
          val = 0;
        }

        result += val;
      }

      // Multiple of 3
      if (result.length % 3) {
        result += Array((3 - result.length % 3) + 1).join('0');
      }

      // Multiple of 6
      if (result.length % 6) {
        // result += Array((6 - result.length % 6) + 1).join("0");
      }

      // Split in 3 groups with equal size
      const regexp = new RegExp('([A-Z0-9]{' + result.length / 3 + '})', 'i');
      result = result.split(regexp);

      // Remove first 0 (if there is one at first postion of every group
      if (result[1].length > 2) {
        if (+result[1].charAt(0) === 0 || +result[3].charAt(0) === 0 || +result[5].charAt(0) === 0) {
          result[1] = result[1].substr(1);
          result[3] = result[3].substr(1);
          result[5] = result[5].substr(1);
        }
      }

      // Truncate (first 2 chars stay, the rest gets deleted)
      result[1] = result[1].slice(0, 2);
      result[3] = result[3].slice(0, 2);
      result[5] = result[5].slice(0, 2);

      // Add element if color consists of just 1 char per color
      if (result[1].length === 1) {
        result[1] += result[1];
        result[3] += result[3];
        result[5] += result[5];
      }

      // Convert to RGB
      r = parseInt(result[1], 16);
      g = parseInt(result[3], 16);
      b = parseInt(result[5], 16);
    } catch (e) {
    }

    return '#' + rgbToHex(r) + rgbToHex(g) + rgbToHex(b);
  }

  static getUniqueId(size: number = 9) {
    return _.sampleSize(<any>'ABCDEFGHIJKLMNOPQRSTUVWXZY1234567890', size).join('');
  }

  static getMultipleUniqueId(total: number, size: number = 9) {
    const uniqueIds = [];

    while (uniqueIds.length < total) {
      let uniqueId = CommonService.getUniqueId(size);
      while (uniqueIds.includes(uniqueId)) {
        uniqueId = CommonService.getUniqueId(size);
      }

      uniqueIds.push(uniqueId);
    }

    return uniqueIds;
  }

  static filterArrayLike(arr: any[], pathToMatch: string, query: string) {
    return arr.filter(o => {
      const regex = new RegExp(query, 'gi');
      const valToMatch = _.get(o, pathToMatch, '');
      return regex.test(valToMatch);
    });
  }

  static syncFormControls(controls: any = {}) {
    controls = _.castArray(controls);

    const subscriptions: Subscription[] = [];

    const sync = (control) => {
      return control.from.valueChanges.debounceTime(500).subscribe(value => {
        control.to.setValue(value);
      });
    };

    _.forEach<any, any>(controls, (control, controlIndex) => {
      subscriptions[controlIndex] = sync(control);
    });

    return {
      disconnect: (index: number) => {
        if (subscriptions[index]) {
          subscriptions[index].unsubscribe();
          subscriptions.splice(index, 1);
        }
      },
      sync: (control) => {
        control.to.setValue(control.from.value);
        subscriptions.push(sync(control));

        return subscriptions.length - 1;
      }
    };
  }

  static baseName(targetPath: string) {
    return targetPath.split(/[\\/]/).pop();
  }

  static joinToString(strings: (string | number)[], separator: string = '.') {
    return strings.filter(s => s !== null && s !== undefined && s !== '').join(separator);
  }

  static assignObjectTreePath(
    object: Object,
    childPaths: string[] = [],
    treePathSourceName: string = 'id',
    treePathPropertyName: string = 'treePath',
    treeParentPathPropertyName: string = 'treeParentPath',
    ignoreArrayIndex: boolean = false,
    treeParentPath: string = '',
  ) {
    _.forEach(object, (o, oIndex) => {
      let objectTreePath = _.get(o, treePathSourceName);
      if (!ignoreArrayIndex && _.isArray(object)) {
        objectTreePath = `${objectTreePath}.${oIndex}`;
      }

      _.set(o, treeParentPathPropertyName, treeParentPath); // set treeParentPath

      objectTreePath = treeParentPath ? `${treeParentPath}.${objectTreePath}` : objectTreePath;
      _.set(o, treePathPropertyName, objectTreePath); // set treePath

      childPaths.forEach(childPath => {
        if (_.has(o, childPath)) {
          const children = _.get(o, childPath);
          if (_.size(children)) {
            CommonService.assignObjectTreePath(children, childPaths, treePathSourceName, treePathPropertyName, treeParentPathPropertyName, ignoreArrayIndex, objectTreePath);
          }
        }
      });
    });
  }

  static cloneClassInstance<T>(classInstance: T) {
    return Object.assign(Object.create(Object.getPrototypeOf(classInstance)), classInstance) as T;
  }

  // TODO: Refactor autocomplete related functions below to a new service rather than being here

  static searchLocalACItems(ev, sourceVarName: string, suggestionVarName: string) {
    this[suggestionVarName] = [];
    this[suggestionVarName] = CommonService.filterArrayLike(this[sourceVarName], 'name', ev.query);
  }

  static onLocalACDropdown(ev, sourceVarName: string, suggestionVarName: string) {
    setTimeout(() => {
      this[suggestionVarName] = this[sourceVarName];
    }, 100);
  }

  // static remoteACItemsHandler(params: {
  //   remoteParams: Function,
  //   remoteRequest: Function,
  //   remoteRequestMap: Function,
  //   ACConfig?: any,
  //   element?: Function,
  // }) {
  //   const store: any = {};
  //   const bind = (type) => {
  //     const methodParams = [
  //       store,
  //       type,
  //       params.remoteParams,
  //       params.remoteRequest,
  //       params.remoteRequestMap,
  //       params.ACConfig || {},
  //     ];
  //     if (params.element) {
  //       methodParams.push(params.element);
  //     }
  //     return CommonService.populateRemoteACItems.bind({}, ...methodParams);
  //   };
  //   return {
  //     search: bind('search'),
  //     dropdown: bind('dropdown'),
  //   };
  // }

  static observableWaitAll(obs, name) {
    return observableZip(...(_.get(obs, name, [])) || []);
  }

  // private static populateRemoteACItems<T>(
  //   store: {
  //     [key: string]: any,
  //   },
  //   type: string,
  //   remoteParams: Function,
  //   remoteRequest: Function,
  //   remoteRequestMap: Function,
  //   ACConfig: any = {},
  //   element: Function | AutoComplete,
  //   event: any,
  // ) {
  //   let suggestions = [];

  //   let targetElement: AutoComplete;
  //   if (_.isFunction(element)) {
  //     const elementFn: Function = <Function>element as Function;
  //     targetElement = elementFn();
  //   } else {
  //     targetElement = <AutoComplete>element;
  //   }

  //   // if (!$(targetElement.inputEL.nativeElement).hasClass('loading')) {
  //   //   $(targetElement.inputEL.nativeElement).addClass('loading');
  //   // }

  //   store.lastQuery = store.currentQuery;
  //   store.currentQuery = event.query;

  //   const lastSuggestion = _.get(store, 'lastSuggestions', []);
  //   if (store.currentQuery === store.lastQuery && lastSuggestion.length > 1 && store.skipThisIfLine) {
  //     /*
  //     *  restore cached suggestions if query is unchanged and cached suggestions are more than 1
  //     *  but currently always skip this block because hasn't been accepted by boss
  //     */
  //     suggestions = store.lastSuggestions;
  //     store.obsSuggestions = observableOf(suggestions);
  //   } else {
  //     const params = remoteParams(event, type);
  //     store.obsSuggestions = remoteRequest(...params)
  //       .pipe(
  //         map(<any>remoteRequestMap),
  //         tap(remoteSuggestions => store.lastSuggestions = remoteSuggestions),
  //       );
  //   }

  //   store.lastExecute = type;

  //   if (store.subSuggestions) {
  //     store.subSuggestions.unsubscribe();
  //   }
  //   store.subSuggestions = store.obsSuggestions.subscribe(populatedSuggestions => {
  //     if (ACConfig.prependNull) {
  //       populatedSuggestions = _.range(0, +ACConfig.prependNull).map(() => null).concat(populatedSuggestions);
  //     }

  //     targetElement.suggestions = populatedSuggestions;
  //     targetElement.handleSuggestionsChange();

  //     // $(targetElement.inputEL.nativeElement).removeClass('loading');
  //   });
  // }

  static removeNonUnicodeCharacterFromString(str: string) {
    return str.replace(/[^\x00-\x7F]/g, '');
  }
}
