import { AxiosResponse } from 'axios';
import * as _ from 'lodash';

export class TemporaryFixService {
  adressObjectNullHandler(axiosResponse: AxiosResponse) {
    const properties = ['billingAddress', 'shippingAddress', 'postalAddress'];
    if (axiosResponse.status === 200) {
      const responseData = axiosResponse.data;
      _.forEach(properties, property => {
        if (_.has(responseData, property) && _.get(responseData, property) === null) {
          _.set(responseData, property, {});
        }
      });
    }
  }
}
