import { RequestService } from './request.service';
import { IRequestLocation, IRequestPostalCode } from '../types/master';

const requestService = new RequestService();
let request: RequestService;

export default function MasterRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_SHIPDEO_MAIN']}`,
    useInterceptor,
    withoutInterceptor
  );
  return {
    getAllLocations(body: IRequestLocation) {
      return request.post<any>('/master/locations', body);
    },

    getAllPostalCode(body: IRequestPostalCode) {
      return request.post<any>('/master/locations/postalcode', body);
    }
  }
}
