import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function ShipmentOrderRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v2/orders`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    createBatch(payload) {
      return request.post('batch', payload);
    },

    updateBatch(payload) {
      return request.post('batch', payload);
    },

    update(id, payload) {
      return request.put(id, payload);
    },

    findDetail(id) {
      return request.get(id);
    },

    findAll(params, body: any = {}) {
      return request.post('', body, params);
    },

    getRelatedData() {
      return request.get('/related-data');
    },

    getPaymentMethods() {
      return request.get('payment-method');
    },

    getAirwaybill(body: any = {}) {
      return request.post('airwaybill', body);
    },

    updateOrderBatch(payload: any) {
      return request.put('batch', payload);
    },

    importOrder(payload: any) {
      return request.post('import', payload);
    },

    uploadAttachment(payload: any) {
      return request.post('upload-attachment', payload);
    },

    renewOrder(payload: any) {
      return request.post('renew', payload);
    }
  }

}
