import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;
let requestv2: RequestService;

export default function PaymentRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/payment`,
    useInterceptor,
    withoutInterceptor
  );

  requestv2 = requestService.new(
    `${baseUrl['API_MAIN']}/v2/finances/payments`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    createPayment(payload: any) {
      return request.post('', payload);
    },

    createPaymentV2(payload: any) {
      return requestv2.post('create', payload);
    },

    cancelPayment(id) {
      return request.delete(`${id}`);
    },

    reviewPayment(id, payload) {
      return request.put(`${id}`, payload);
    },

    getPayment(params: any) {
      return request.get('', { params });
    },

    downloadAttachment(filename: string) {
      return request.get<any>(`download-attachment/${filename}`);
    },

    findById(id) {
      return request.get<any>(`${id}`);
    }
  }

}
