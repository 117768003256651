import React, { useEffect, useRef, useState } from 'react';

import { Layout } from 'antd';

import Package from '../../Package';
import { SiderLayoutProps } from './sider-layout';

import useLayout from '../../../hooks/useLayout';
import { environment } from 'apps/portal/src/environments/environment';
import './sider-layout.component.scss';
import { AclService } from '../../../core/auth/acl.service';

const { can } = new AclService();
export const SiderLayoutComponent = (props: SiderLayoutProps) => {
  const { Sider } = Layout;

  const ref = useRef(null);
  const { dispatch } = useLayout();
  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState<number | string>('auto');

  useEffect(() => {
    if (collapsed) {
      setWidth('0');
    } else {
      setWidth('auto');
    }

    props.onCollapseChanges && props.onCollapseChanges(collapsed);
  }, [collapsed, props.compact]);

  useEffect(() => {
    dispatch({
      type: 'update',
      name: 'siderWidth',
      value: ref.current ? ref.current.offsetWidth : 0,
    });
  }, [ref.current]);

  return (
    <Sider
      ref={ref}
      width={width}
      collapsedWidth={'0'}
      collapsed={collapsed}
      className="sider-admin-deo"
      breakpoint="xl"
      onCollapse={setCollapsed}
      onBreakpoint={(broken) => {
        if (broken) {
          setCollapsed(true);
        } else {
          setCollapsed(false);
        }
      }}
    >
      <div className="content-sider-wrapper">
        <div className="pb-4 d-flex justify-content-center img-shipdeo">
          <img alt="shipdeo portal" className="shipdeo-logo" src={environment.CONTENTS.COMPANY_LOGO} />
        </div>
        <div className="mt-2">
          {props.addOrder || ''}
        </div>
        <div className="menu-item">
          <div>
            {props.menu || ''}
          </div>
          {/* <div className="p-2 user-status">
            <Package />
            <div className="text-center">
              <p className="copyright">&copy; {new Date().getFullYear()} Shipdeo</p>
            </div>
          </div> */}
        </div>
      </div>
    </Sider>
  );
};
