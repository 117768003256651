import { useState, useEffect } from 'react';

import { split, find, has, isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AclService } from '../core/auth/acl.service';
import useLocalData from './useLocalData';
import { UserStatusType } from '../../global';
import { isShowVerificationPopup } from '../components/VerificationPopup/utils';

export interface IUseValidateMenus {
  menus: IMenu[];
  parentMenu: IMenu;
  setNewParentMenus: React.Dispatch<React.SetStateAction<IMenu>>;
  setNewMenus: (menus: IMenu[]) => void;
  isShowVerification: boolean | string;
  isShowCompany: boolean | string;
}

const useMenus = (options?): IUseValidateMenus => {
  const acl = new AclService();
  const [menus, setMenus] = useState(acl.allowedMenus());
  const [parentMenu, setParentMenu] = useState({} as IMenu);
  const location = useLocation();
  const { t } = useTranslation();
  const { store: { relatedData }, dispatch } = useLocalData();
  const status: UserStatusType = relatedData?.status || null;
  const userType = relatedData?.userType || 'B2C';
  const [isShowVerification, setIsShowVerification] = useState<boolean | string>(['pending_verification', 'rejected_verification', 'pending_upgrade', 'rejected_upgrade', 'incomplete_upgrade'].includes(status) || userType === 'B2C');
  const [isShowCompany, setIsShowCompany] = useState<boolean | string>(['pending_upgrade', 'rejected_upgrade', 'upgraded', 'incomplete_upgrade'].includes(status));

  // console.log(location);


  useEffect(() => {
    let validMenus = acl.allowedMenus();
    let newValidMenus = validMenus;
    if (userType === 'B2C') {
      newValidMenus = validMenus.filter(menu => (menu.id || '').toLowerCase() !== 'shipdeo-apps');
    }
    setIsShowVerification(['pending_verification', 'rejected_verification', 'pending_upgrade', 'rejected_upgrade', 'incomplete_upgrade'].includes(status) || isShowVerificationPopup(relatedData));
    setIsShowCompany(['pending_upgrade', 'rejected_upgrade', 'upgraded', 'incomplete_upgrade'].includes(status));
    setMenus(newValidMenus);

    return () => {
      false;
    }
  }, [relatedData]);

  useEffect(() => {
    if (menus && location.pathname) {
      const parentPath = split(location.pathname, '/');
      let menu = findMenuById(menus, parentPath[parentPath.length - 1]);

      // if (menu?.id === 'setting') {
      //   if (menu.subs) {
      //     if (!isShowVerification) {
      //       menu.subs = menu.subs.filter(m => m.id !== 'verification');
      //     }

      //     if (!isShowCompany) {
      //       menu.subs = menu.subs.filter(sub => sub.id !== 'company-info');
      //     }

      //     if (userType === 'B2C') {
      //       menu.subs = menu.subs.filter(sub => sub.id !== 'integration');
      //     }
      //   }
      // }
      if (menu?.id === 'balance') {
        menu = null;
      }

      // dispatch({
      //   type: 'update',
      //   name: 'menuTitle',
      //   value: conditionalMenuTitle(menu)
      // });
      setParentMenu(menu);
    }
  }, [menus, location.pathname, isShowVerification, isShowCompany]);

  // function conditionalMenuTitle(menu) {
  //   // if (has(menu, 'subs') && menu?.subs?.length && menu?.subs?.find(m => m.to === location.pathname)?.label) return t(menu?.subs?.find(m => m.to === location.pathname)?.label);
  //   if (location.pathname?.includes('verification')) return t('Verifikasi');
  //   // if (location.pathname?.includes('integration')) return t('Integration');
  //   return t(menu?.label);
  // }

  function findMenuById(menuList, id) {
    let foundMenu = menuList.find(menu => menu.id === id);
  
    if (!foundMenu) {
      menuList.some(menu => {
        if (menu.subMenu) {
          // buat sekarang find submenunya by id dulu (kalau ada issue bisa di sesuaikan lagi)
          const subMenuData = menu.subMenu.filter(sub => sub.id === id);
          // const subMenuData = menu.subMenu.filter(sub => sub.to.includes(id));
          if (subMenuData.length > 0) {
            foundMenu = subMenuData[0];
          }
        }
      });
    }
  
    return foundMenu;
  }

  function handleSetNewMenus(newMenus: IMenu[]) {
    setMenus(newMenus);
  }

  return {
    menus,
    parentMenu,
    setNewMenus: handleSetNewMenus,
    setNewParentMenus: setParentMenu,
    isShowVerification,
    isShowCompany
  };
};

export default useMenus;
