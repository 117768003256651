import branch from './branch.json';
import dashboard from './dashboard.json';
import finance from './finance.json';
import integration from './integration.json';
import promo from './promo.json';
import returnManagement from './return-management.json';
import returning from './returning.json';
import reportShipment from './reportShipment.json';
import shipmentOrder from './shipmentOrder.json';
import widget from './widget.json';
import trackingAirwaybill from './trackingAirwaybill.json';

export default {
  notification: {
    success: 'Berhasil',
    error: 'Kesalahan',
    failed: 'Gagal',
    contact: {
      success: {
        create: 'Kontak Telah Ditambahkan',
        update: 'Kontak Telah Diperbarui!',
        delete: 'Kontak Telah Dihapus',
      },
      failed: {
        create: '',
        update: '',
        detele: 'Kontak Gagal Dihapus',
      },
      confirmation: {
        title: 'Konfirmasi',
        message:
          'Apakah anda yakin ingin menghapus kontak dengan nama {{name}}?',
        action: {
          submit: 'Ok',
          cancel: 'Batalkan',
        },
      },
    },
    maps: {
      zipCodeError:
        'Lokasi yang Anda pilih bukan termasuk area Kecamatan yang anda masukkan!',
      setLocation: {
        success: 'Berhasil menetapkan Lokasi!',
        failed: 'Gagal menetapkan Lokasi!',
      },
    },
    user: {
      userDeleted: 'Pengguna telah dihapus!',
      userDeleteError: 'Gagal Menghapus Pengguna',
      userCreated: 'Pengguna telah ditambahkan!',
      userUpdated: 'Pengguna telah diperbarui!',
      branch: {
        branchCreated: 'Cabang telah ditambahkan!',
        branchUpdated: 'Cabang telah diperbarui!',
        branchDeleted: 'Cabang telah dihapus!',
        branchDelete_error: 'Gagal menghapus branch',
      },
      department: {
        departmentCreated: 'Departemen telah ditambahkan!',
        departmentUpdated: 'Departemen telah diperbarui!',
        departmentDeleted: 'Departemen telah dihapus!',
        departmentDeleteError: 'Gagal menghapus departemen!',
      },
      deleteConfirm: 'Apakah Anda yakin ingin menghapus',
      map: {
        zipCodeError: 'Lokasi Kodepost Alamat dan map berbeda',
        zipCodeSuccess: 'Lokasi Kodepost Alamat dan map sama',
      },
    },
    upload: {
      success: 'File telah diunggah!',
      failed: 'Gagal mengunggah file!',
      wrong: 'Tipe file salah!',
      onlyImage: 'Hanya file gambar yang diperbolehkan!',
      onlyExcel: 'Hanya file xls atau xlsx yang diperbolehkan!',
    },
    errorForm: 'Terjadi kesalahan pada formulir',
    successSendData: 'Data berhasil dikirim!',
    updatedWithVariable: '{{name}} telah diperbarui!',
  },
  ui: {
    action: {
      hide: 'Sembunyikan',
      show: 'Tampilkan'
    },
    field: {
      message: '{{field}} tidak boleh kosong',
    },
    filter: {
      date: {
        end: 'Tanggal Akhir',
        start: 'Tanggal Awal',
      },
      monthly: 'Bulanan',
      search: 'Cari',
      weekly: 'Mingguan',
    },
    adjustment: {
      list: {
        column: {
          adjustmentNumber: 'No. Adjustment',
          amount: 'Amount',
          courierService: 'Layanan Kurir',
          dueDate: 'Tanggal Jatuh Tempo',
          issueDate: 'Tanggal Terbit',
          notes: 'Catatan',
          receiptNumber: 'Receip Number',
          type: 'Tipe',
        },
        title: 'List Adjustment',
      },
    },
    invoice: {
      list: {
        column: {
          invoiceDate: 'Tanggal Invoice',
          invoiceDueDate: 'Tanggal Jatuh Tempo',
          invoiceId: 'ID Invoice',
          numberId: 'Nomor ID',
          orderAmount: 'Jumlah Order',
          paymentDate: 'Tanggal Pembayaran',
          status: 'Status',
          totalBill: 'Total Tagihan',
        },
        title: 'List Invoice',
      },
      widget: {
        status: {
          '1': 'Belum Jatuh Tempo',
          '2': 'Lunas',
          '3': 'Menunggu konfirmasi Pembayaran',
          '4': 'Menunggu Pembayaran',
          '5': 'Jatuh Tempo',
        },
      },
    },
    invoiceDetail: {
      bill: {
        billDueDate: 'Tanggal Jatuh Tempo',
        orderAmount: 'Jumlah Order',
        totalBill: 'Total Tagihan',
        title: 'Nilai Tagihan',
      },
      entity: {
        actualCharge: 'Aktual Ongkir',
        actualWeight: 'Aktual Berat',
        airwaybill: 'No. Resi',
        courier: 'Layanan',
        deliveredDate: 'Tgl. Pengiriman',
        discountCharge: 'Diskon Ongkir',
        insurance: 'Asuransi',
        receiverAddress: 'Alamat Penerima',
        totalBill: 'Total Tagihan',
      },
      filter: {
        courier: 'Pilih Kurir',
      },
      invoice: {
        date: 'Tanggal',
        no: 'No. Invoice',
        paymentId: 'ID Pembayaran',
        sellerName: 'Nama Penjual',
        title: 'Detail Invoice',
        status: {
          unpaidStatus: 'Belum Lunas',
          partiallyPaid: 'Dibayar Sebagian',
          alreadyPaidStatus: 'Lunas',
          onReviewStatus: 'Menunggu Konfirmasi',
          waitingForPayment: 'Menunggu Pembayaran',
          expiredInvoice: 'Kedaluwarsa',
          voidStatus: 'Batal',
        },
      },
      status: {
        dueDate: 'Tanggal Jatuh Tempo',
        invoice: 'Status Invoice',
        paymentDate: 'Tanggal Pembayaran',
        title: 'Status',
      },
      evidenceOfTransfer: 'Bukti Transfer',
      payBill: 'Bayar Tagihan',
      title: 'Detail Invoice',
      uploadEvidenceOfTransfer: 'Upload Butkti Transfer',
      payment: {
        title: 'Payment',
      },
    },
    order: {
      list: {
        orderDate: 'Order',
        reqPickupDate: 'Req Pickup',
        customer: 'Pelanggan',
        shippingAddress: 'Alamat Pengiriman',
        CodPrice: 'Nilai COD',
        priceOfGoods: 'Harga Barang',
        orderNumber: 'Nomor Order',
        trackNumber: 'Nomor Track'
      },
      total: 'Total Order'
    },
    menu: {
      balance: 'Balance',
      bank: 'Perbankan',
      bill: 'Tagihan',
      branch: 'Alamat Pengirim / Branch',
      claim: 'Klaim',
      checkAirwaybill: 'Cek Resi',
      company: 'Perusahaan',
      companyBank: 'Perusahaan & Perbankan',
      configuration: 'Konfigurasi',
      customImportOrder: 'Custom Template Import Order',
      dashboard: 'Dasbor',
      department: 'Department',
      invoice: 'Invoice',
      order: 'Order',
      newOrder: 'Order Baru',
      setting: 'Pengaturan',
      contact: 'Kontak',
      expedition: 'Ekspedisi',
      finance: 'Finance',
      integration: 'Integrasi API',
      monitoring: 'Monitoring',
      permission: 'Hak Akses',
      profile: 'Profil',
      promo: 'Promo',
      report: 'Laporan',
      reportCity: 'Pengiriman per Kota',
      reportCourier: 'Pengiriman per Kurir',
      reportShipmentCOD: 'Pengiriman COD',
      reportStatus: 'Pengiriman per Status',
      return: 'Kelola Retur',
      returning: 'Returning',
      returnManagement: 'Return Management',
      returnPotensial: 'Potensial Return',
      returnReceived: 'Return Diterima',
      shipdeoApps: 'Shipdeo Apps',
      shipment: 'Pengiriman',
      shippinglabel: 'Shipping Label',
      shippingCost: 'Cek Ongkos Kirim',
      topup: 'Top Up',
      user: 'Pengguna',
      trackingAirwaybill: 'Cek Resi',
      verification: 'Verifikasi',
      withdraw: 'Withdraw'
    },
    entity: {
      accessRoles: 'Hak Akses',
      name: 'Nama Lengkap',
      phone: 'Nomor Telepon',
      address: 'Alamat',
      email: 'Email',
      company: 'Nama Perusahaan',
      link: 'Link',
      shipperName: 'Nama Pengirim',
      npwp: 'NPWP',
      note: 'Catatan',
      subdistrict: 'Kecamatan',
      city: 'Kota/Kab',
      province: 'Provinsi',
      postcode: 'Kode Pos',
      latitude: 'Latitude',
      longitude: 'Longitude',
      password: 'Kata Sandi',
      fax: 'Faksimili',
      website: 'Situs Web',
      branch: 'Cabang',
      branchName: 'Nama Cabang',
      department: 'Departemen',
      code: 'Kode',
      coordinat: 'Koordinat',
      personalData: 'Data Pribadi',
      banking: 'Informasi Perbankan',
      companyInfo: 'Informasi Perusahaan',
      identityNumber: 'Nomor KTP',
      identityName: 'Nama Sesuai KTP',
      bankName: 'Nama Bank',
      accountOwnerName: 'Nama Pemilik Rekening',
      accountNumber: 'Nomor Rekening',
      verified: 'Terverifikasi',
      rejected: 'Ditolak',
      pending: 'Menunggu Verifikasi',
      sender: 'Pengirim',
      senderName: 'Nama Pengirim',
      receiver: 'Penerima',
      receiverName: 'Nama Penerima',
      action: {
        close: 'Tutup',
        save: 'Simpan',
        cancel: 'Batal',
        upload: 'Unggah',
        delete: 'Hapus',
      },
      placeholder: {
        address: 'Masukan Alamat',
        company: 'Masukan Nama Perusahaan',
        district: 'Cari Kecamatan..',
        email: 'Masukan Email',
        name: 'Masukan Nama',
        note: 'Masukan Catatan',
        postalCode: 'Kode'
      },
    },
    maps: {
      card: {
        locationDetail: 'Detail Lokasi',
        address: {
          subdistrict: 'Kecamatan',
          city: 'Kab/Kota',
          province: 'Provinsi',
          postcode: 'Kode Pos',
        },
        action: {
          setLocation: 'Tetapkan',
        },
      },
    },
    contact: {
      maps: {
        showMap: 'Buka Maps',
        closeMap: 'Tutup Maps',
        chooseCoordinate: 'Pilih Kordinat',
        changeCoordinate: 'Ubah Kordinat',
        chooseLocation: 'Set Lokasi',
      },
      action: {
        add: 'Tambah Kontak',
        edit: 'Perbarui Kontak',
        showLocation: 'Lihat Lokasi'
      },
      detail: {
        addressInfo: 'Informasi Alamat',
        generalInfo: 'Informasi Umum',
        locationInfo: 'Detail Lokasi',
        title: 'Detail Kontak',
      },
    },
    payment: {
      list: {
        column: {
          issueDate: 'Tanggal Terbit',
          payment: 'Pembayaran',
          paymentId: 'ID Pembayaran',
          paymentNumber: 'No. Pembayaran',
          paymentType: 'Tipe Pembayaran',
          restOfBill: 'Sisa Tagihan',
          tenantName: 'Nama Tenant',
          totalBill: 'Total Tagihan',
          totalInvoice: 'Total Invoice',
        },
        title: 'List Pembayaran',
      },
      paymentDetail: {
        attachment: 'Attachment',
        billValue: 'Nilai Tagihan',
        invoice: 'Invoice',
        notes: 'Catatan',
        paymentDate: 'Tanggal Pembayaran',
        paymentNumber: 'No. Pembayaran',
        phoneNumber: 'No. Telepon',
        restOfBill: 'Sisa Tagihan',
        tenantId: 'Tenant ID',
        tenantName: 'Nama Tenant',
        title: 'Detail Pembayaran',
        totalInvoice: 'Total Invoice',
        totalInvoiceAmount: 'Jumlah Nominal Tagihan',
        totalOrder: 'Total Order',
        totalPayment: 'Total Pembayaran',
      },
    },
    setting: {
      profile: {
        addPhoneNumber: {
          title1: 'Masukkan Nomor HP',
          title2: 'Dapatkan Kode OTP',
          title3: 'Verifikasi OTP',
          description1: 'Tambahkan no. handphone untuk mempermudah kamu mengakses portal shipdeo serta mendapatkan pembaharuan dari shipdeo.',
          description2: 'Verifikasikan akun Shipdeo milikmu dengan memilih salah satu metode dibawah ini untuk dapat kode OTP',
          description3: 'Segera masukkan kode OTP yang telah dikirim ke nomor {{phone}}'
        },
        companyInfo: {
          title: 'Info Perusahaan',
          upload: 'Unggah',
        },
        contactInfo: {
          title: 'Profil Pengguna',
        },
        referralCode: {
          title: 'Referral Code',
          generate: 'Generate',
          warning: 'Akun anda belum memiliki kode referral. Silahkan Generate terlebih dahulu!',
          info: 'Gunakan kode referral untuk ajak teman kamu bergabung dan dapatkan benefit lainnya dari program Referral Shipdeo.',
        },
        bankAccount: {
          title: 'Akun Bank',
          bankName: 'Nama Bank',
          accountName: 'Nama Akun',
          accountNumber: 'Nomor Akun',
          bankAddress: 'Alamat Bank',
          branch: 'Cabang',
        },
        authorization: {
          title: 'Authorization',
        },
        changePhoneNumber: {
          title1: 'Ubah Nomor Telepon',
          title2: 'Nomor Telepon dan OTP',
          title3: 'Verifikasi OTP',
          description1:
            'Silahkan edit nomor telepon Anda, selanjutnya kami akan mengirim pesan berisi kode OTP ke nomor tersebut',
          description2:
            'Kami akan mengirim kode OTP ke nomor telepon Anda. Pilih salah satu metode di bawah ini',
          description3:
            'Kami mengirim kode OTP melalui Whatsapp ke nomor {{phone}}. Masukkan kode tersebut pada kolom di bawah ini',
        },
        changeEmail: {
          title1: 'Ubah Email',
          title2: 'Verifikasi OTP',
          description1:
            'Silahkan edit email Anda, selanjutnya kami akan mengirim pesan berisi kode OTP ke email tersebut',
          description2:
            'Kami mengirim kode OTP melalui Email ke {{email}}. Masukkan kode tersebut pada kolom di bawah ini',
        },
      },
      apiKey: {
        shopName: {
          label: 'Nama Toko',
          placeholder: 'Masukkan nama'
        },
        couriers: {
          label: 'Pilih Kurir',
          placeholder: 'Masukkan kurir pilihan'
        },
        service: 'Layanan',
        platform: 'Platform'
      },
      shippingLabel: {
        showItemList: 'Show Item List',
        showOrderNumber: 'Show Order Number',
        showOrderNumberBarcode: 'Show Order Number Barcode',
        showSenderAddress: 'Show Sender Address',
        showShippingCharge: 'Show Shipping Charge',
        showAllItems: 'Show All Item'
      },
      expedition: {
        error: 'Gagal, Silahkan aktifkan salah satu kurir sebelum menyimpan perubahan',
        success: 'Berhasil mengubah ekspedisi'
      },
      verification: {
        description: 'Untuk menjaga keamanan dan mencegah tindakan yang tidak sah, kami memerlukan beberapa dokumen sebagai bagian dari proses verifikasi. Dokumen yang kami butuhkan termasuk foto KTP (Kartu Tanda Penduduk) dan juga foto selfie Anda. Dengan menggunakan dokumen-dokumen ini, kami dapat memastikan bahwa Anda adalah pengguna yang sah. Terima kasih atas kerjasama dan pengertian Anda.',
        title: 'Verifikasi Dokumen',
        upload: 'Lengkapi Dokumen'
      }
    },
    user: {
      accessRoles: {
        placeholder: 'Pilih Hak Akses',
        title: 'Salin hak akses dari'
      },
      action: {
        confirm: 'Ya, Tinggalkan'
      },
      cancelAdd: {
        description: 'Dengan meninggalkan pengisian form, informasi yang terisi akan hilang.',
        title: 'BATALKAN TAMBAH PENGGUNA?'
      },
      userCode: 'Kode Pengguna',
      userName: 'Nama Pengguna',
      userDetail: 'Detail Pengguna',
      passwordGenerate: 'Menghasilkan Password',
      addUser: 'Tambah Pengguna',
      updateUser: 'Perbarui Pengguna',
      branch: {
        branchName: 'Nama Cabang',
        branchCode: 'Kode Cabang',
        branchDetail: 'Detail Cabang',
        districtName: 'Nama Kecamatan',
        selectBranch: 'Pilih Cabang',
        addBranch: 'Tambah Cabang',
        updateBranch: 'Perbarui Cabang',
        hasLocation: 'Sudah Diatur',
        hasNotLocation: 'Belum Diatur',
      },
      department: {
        departmentName: 'Nama Departemen',
        departmentCode: 'Kode Departemen',
        selectDepartment: 'Pilih Departemen',
        addDepartment: 'Tambah Departemen',
        updateDepartment: 'Ubah Departemen',
      },
      confirmation: 'konfirmasi',
      required: 'Bagian ini harus diisi',
      requiredEmail: 'Format email salah',
      lowercaseEmail: 'Gunakan huruf kecil',
      cancel: 'Batal',
      save: 'Simpan',
      map: {
        showMap: 'Buka Maps',
        closeMap: 'Tutup Maps',
        detail: 'Detail Lokasi',
        src: 'Cari Lokasi',
        chooseCoordinate: 'Pilih Kordinat',
        changeLocation: 'Ubah Lokasi',
        chooseLocation: 'Tetapkan'
      },
      permission: {
        balance: 'Balance',
        dashboard: 'Dasbor',
        order: 'Order',
        finance: 'Finance',
        contact: 'Kontak',
        information: 'Informasi',
        paymentMethod: 'Metode Pembayaran',
        status: {
          active: 'Aktif',
          inactive: 'Tidak Aktif'
        }
      }
    },
    upload: {
      identity: 'Unggah Foto KTP',
      identitySelfie: 'Unggah Foto Selfie dengan KTP',
      bank: 'Unggah foto Buku Tabungan',
      description: 'Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px (Untuk gambar optimal gunakan ukuran minimum 700 x 700 px).',
    },
    button: {
      action: 'Tindakan',
      back: 'Kembali',
      download: 'Unduh',
      export: 'Ekspor',
      import: 'Impor',
      save: 'Simpan',
      saveChanges: 'Simpan Perubahan',
      cancel: 'Batal',
      upload: 'Unggah',
      delete: 'Hapus',
      edit: 'Ubah',
      editWithVariable: 'Ubah {{name}}',
      addWithVariable: 'Tambah {{name}}',
      arrangeDelivery: 'Atur Pengiriman',
      withdrawTnC2: 'Syarat dan Ketentuan Penarikan Saldo.',
      pay: 'Bayar',
      next: 'Selanjutnya'
    },
    balance: {
      balanceAccount: 'Lakukan Verifikasi Akun untuk memunculkan Nomor Rekening',
      paymentMethod: 'Cara Pembayaran',
      waitingPayment: 'Menunggu Pembayaran',
      nominalTopup: 'Nominal yang ingin di Top Up?',
      payNow: 'Bayar Sekarang',
      adminReview: 'Ditinjau admin',
      success: 'Berhasil',
      canceled: 'Dibatalkan',
      history: 'Riwayat',
      withdrawCondition: 'Syarat dan Ketentuan Penarikan Saldo',
      agree: 'Setuju',
      all: 'Saldo Balance',
      pending: 'Proses Pencairan',
      tooltipAll: 'Jumlah dana yang kamu miliki di Balance Shipdeo',
      tooltipDebit: 'Jumlah dana yang sedang dalam proses pencairan ke rekeningmu',
      tooltipAvailable: 'Dana COD kamu yang masih dalam proses pengiriman',
      tooltipCredit: 'Dana COD kamu yang sudah masuk ke balance',
      readyToWithdraw: 'Sedang Dikirim',
      onProccess: 'Sudah Diterima Pembeli',
      minimumWithdraw: 'Minimum penarikan Rp 10.000',
      balance: 'Saldo',
      withdrawAmount: 'Nominal Penarikan',
      withdrawAll: 'Tarik Semua',
      amount: 'Jumlah',
      destinationAccount: 'Rekening Tujuan',
      withdrawTnC1: 'Dengan klik tombol Withdraw, kamu telah setuju dengan',
      withdrawTnC2: 'Syarat dan Ketentuan Penarikan Saldo',
      successMessage: 'Berhasil melakukan Top Up Balance!',
      status: {
        pending: 'Menunggu Pembayaran',
        reviewAdmin: 'Review Admin',
        waitingConfirm: 'Menunggu Konfirmasi',
        paid: 'Berhasil',
        void: 'Dibatalkan',
        failed: 'Gagal',
        rejected: 'Ditolak',
        approved: 'Sedang Diproses',
        done: 'Sudah Ditransfer',
        review: 'Ditinjau Admin',
      },
    },
  },
  tables: {
    head: {
      attachment: 'Lampiran',
      city: 'Kota',
      date: 'Tanggal',
      disbursementDate: 'Tanggal Pencairan',
      nominal: 'Nominal',
      nominalRequest: 'Nominal Pengajuan',
      paymentMethod: 'Metode Pembayaran',
      timeRequest: 'Waktu Request',
      totalBalance: 'Total Balance',
      source: 'Sumber',
      note: 'Catatan',
      type: 'Tipe',
      createDate: 'Tanggal Dibuat',
      tenantId: 'Tenant ID',
      orderNumber: 'No. Order',
      itemName: 'Nama Barang',
      description: 'Deskripsi',
      deliveryNote: 'Catatan Pengiriman',
      courier: 'Kurir',
      service: 'Layanan',
      cod: 'COD',
      airwaybill: 'No. Resi',
      sender: 'Pengirim',
      senderPhone: 'No. Telepon Pengirim',
      origin: 'Asal',
      originAddress: 'Alamat Pengirim',
      originDistrict: 'Kecamatan Pengirim',
      originCity: 'Kota Pengirim',
      originProvince: 'Provinsi Pengirim',
      receiver: 'Penerima',
      receiverPhone: 'No. Telepon Penerima',
      destination: 'Tujuan',
      destinationAddress: 'Alamat Penerima',
      destinationDistrict: 'Kecamatan Penerima',
      destinationCity: 'Kota Penerima',
      destinationProvince: 'Provinsi Penerima',
      status: 'Status',
      statusNotes: 'Catatan Status',
      courierStatus: 'Status Kurir',
      courierPickedDate: 'Tanggal Pick Up',
      deliveredDate: 'Tanggal Terkirim',
      shippingCharge: 'Ongkos Kirim',
      codValue: 'Nilai COD',
      discount: 'Diskon',
      subtotal: 'Subtotal',
      total: 'Total',
      branch: 'Cabang',
      reference: 'Referensi',
      totalCod: 'NILAI COD',
      shipdeoStatus: 'STATUS SHIPDEO',
      shippingCost: 'ONGKOS KIRIM',
      shippinglabel: 'Cetak Shipping Label',
      resiNumber: 'No. RESI',
      orderDate: 'TANGGAL ORDER',
      departement: 'Departement',
      orderType: 'TIPE ORDER',
      codornot: 'COD/Non-COD',
      partnerId: 'Partner ID',
      senderEmail: 'Sender Email',
      billingType: 'Billing Type',
      goodsValue: 'Goods Value',
      codFeePercent: 'COD Fee (%)',
      codFeeValue: 'COD Fee (Rp)',
      weight: 'Berat',
      length: 'Panjang',
      height: 'Tinggi',
      width: 'Lebar',
      discountShippingChargePercent: 'Diskon Shipping Charge (%)',
      discountShippingChargeValue: 'Diskon Shipping Charge (Rp)',
      insurance: 'Insurance',
      woodPacking: 'Packing Kayu',
      estimasiPencairan: 'Estimasi Pencairan',
      estimasiTagihan: 'Estimasi Tagihan',
      actualWeight: 'Actual Weight (Kg)',
      actualShippingChargeAndInsurance: 'Actual Shipping Charge + Insurance',
      codSubTotal: 'COD Subtotal',
    },
  },
  validator: {
    required: 'Bagian ini harus diisi',
    min: 'Pengisian {{name}} minimal {{min}} karakter',
    requiredWithVariable: '{{name}} harus diisi',
    requiredImage: 'Gambar harus diisi',
    email: 'Email tidak valid, contoh : email@shipdeo.com',
    invalid: '{{name}} tidak valid',
    url: 'Link tidak valid'
  },
  dashboard,
  finance,
  integration,
  promo,
  reportShipment,
  returning,
  returnManagement,
  shipmentOrder,
  widget,
  trackingAirwaybill,
  ...branch
};
