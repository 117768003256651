import { AxiosInstance, AxiosResponse, AxiosStatic } from 'axios';
import * as _ from 'lodash';

import { MessageParserService } from '@clodeo/clodeo-core/common/message-parser.service';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';

export class HttpExtsrvService {
  notif = new Notifications;
  messageParseService = new MessageParserService;
  axiosInterceptors(axios: AxiosInstance | AxiosStatic) {
    axios.interceptors.response.use(response => {
      this.handleResponse(response);

      return response;
    }, error => {
      const response = error.response;
      this.handleResponse(response);

      throw error;
    });
  }

  private handleResponse(response: AxiosResponse) {
    if (response?.data?.errors?.length && response?.data?.data) {
      _.set(response.data, 'summary', {});

      this.handleErrors(response);
    }
  }

  private handleErrors(response: AxiosResponse) {

    this.notif.show({
      type: 'error',
      title: 'Error',
      description: response,
      useService: true
    });
  }
}
