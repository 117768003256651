import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function AppRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/apps`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    createCredential(body) {
      return request.post<any>('credentials', body);
    },
    createSignature(body) {
      return request.post<any>('signature', body);
    }
  }
}
