import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function TokenRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_ACCOUNT']}`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    getToken(payload) {
      return request.post<any>('login', payload);
    },

    getTokenByGoogle(payload) {
      return request.post<any>('login/google', payload);
    },

    getClientCredential(username = '', password = '') {
      return request.post<any>(`validate`, null, {
        params: {
          username,
          password
        }
      });
    },

    verifyStatus(id: string) {
      return request.put<any>(`user/active/${id}`, null);
    },

    verifyEmail(payload: any) {
      return request.post<any>(`user/verify-email`, payload);
    },

    resetPassword(id, payload: any) {
      return request.put<any>(`user/reset-password/${id}`, payload);
    },

    refreshToken(payload: any) {
      return request.post<any>(`login`, payload);
    }
  }
}
