import React from 'react';
import { Popover as BasePopover } from 'antd';
import { PopoverProps } from './popover.d';

const popoverType = (type: string) => {
  switch(type) {
    case 'primary' :
      return 'deo-primary';
    case 'basic' :
    default:
      return 'deo';
  }
}

const Popover = (props: PopoverProps) => (
  <BasePopover
    {...props}
    title={props.title}
    overlayClassName={'popover-' + popoverType(props.type) + (props.arrow == false ? ' no-arrow' : '')}
  >
    {props.children}
  </BasePopover>
)

export { Popover };