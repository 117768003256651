import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function ReceiptReturnedRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/receipt-returned`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    findAll(params, body: any = {}) {
      return request.post('list', body, params);
    },

    createEvent(data) {
      return request.post('', data);
    }
  }

}
