import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function ReportRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_SHIPDEO_MAIN']}/reports`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    getReportOrdersCOD(params) {
      return request.get<any>('/orders/cod', params);
    },
    getWidgetReportOrdersCOD(status: string, params: any) {
      return request.get<any>(`/orders/cod/${status}`, params);
    },
    getReportCities(params) {
      return request.get<any>('orders/city', params);
    },
    getReportCouriers(params) {
      return request.get<any>('orders/courier', params);
    },
    getReportStatus(params) {
      return request.get<any>('orders/status', params);
    },
  }

}
