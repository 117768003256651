import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;
let requestv2: RequestService;

export default function InvoiceRest(
  baseUrl,
  useInterceptor: any = true,
  withoutInterceptor: boolean = false
) {
  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/invoices`,
    useInterceptor,
    withoutInterceptor
  );

  requestv2 = requestService.new(
    `${baseUrl['API_MAIN']}/v2/finances/invoices`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    checkBilling() {
      return request.post('check-expired');
    },

    getInvoiceList(params: any) {
      return requestv2.get<any>('list', { params });
    },

    getWidgetInvoice(params: any) {
      return requestv2.get<any>('widget', { params });
    },

    getDetailInvoice(id: string) {
      return requestv2.get<any>(`${id}`);
    },

    getInvoiceById(id: string) {
      return request.get<any>(`detail/${id}`);
    },

    getDownloadInvoice(payload) {
      return request.post<any>('download', payload);
    },

    uploadPaymentProof(payload) {
      return request.post('file/upload', payload);
    },

    getInvoiceByIdList(id: string, params: any) {
      return request.get<any>(`detail-order/${id}`, { params });
    },
  };
}
