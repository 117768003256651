import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function UserRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    getAllUser(params) {
      return request.get<any>('/user/not-owner', params);
    },

    createUser(body: any) {
      return request.post('/user/create/with-permissions', body);
    },

    updateUser(body: any, id: string) {
      return request.put(`/user/${id}/update`, body);
    },

    updateUserBranch(id: string, params: any) {
      return request.patch(`/user/${id}/branch`, null, { params });
    },

    deleteUser(id: string) {
      return request.delete(`/user/${id}/delete`);
    },

    getReferralCode(id) {
      return request.put<any>(`/user/sales-referral/${id}`);
    },

    getUserById(id: string) {
      return request.get<any>(`/user/${id}`);
    },

    getUserByCompany(companyId: string, params) {
      return request.get<any>(`/user/by-company/${companyId}`, params);
    },

    checkNotification(params?) {
      return request.get(`/v1/notification`, {
        params,
      });
    },

    importUser(payload) {
      return request.post<any>(`/user/imports`, payload);
    },

    getRelatedData() {
      return request.get<any>(`/user/related-data`);
    },

    getAllPermission() {
      return request.get<any>('/v1/user/permissions');
    },

    userValidate(body) {
      return request.post<any>(`/user/validate`, body);
    }
  }

}
