import React, { useState } from 'react';
import { AclService } from '../../core/auth/acl.service';
import useLocalData from '../../hooks/useLocalData';
import NumberFormat from 'react-number-format';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';

const { can } = new AclService();
export function BalanceAmount() {
  const { store } = useLocalData();
  const { balanceData, relatedData } = store;
  const [showBalance, setShowBalance] = useState<boolean>(false);

  return can(['balance.view']) && relatedData?.userCompany?.companyIsBalanceShow ? (
    <div className="d-flex align-items-center bg-white balance-amount gap-11px">
      <img src="assets\img\Balance\wallet.svg" className="no-select-img" />
      <div className="font-weight-bold text-dark w-max-content current-balance">
        {showBalance ? (
          <NumberFormat
            value={balanceData?.totalAvailable || 0}
            displayType={'text'}
            thousandSeparator="."
            decimalSeparator=","
            prefix={'Rp. '}
            fixedDecimalScale={true}
          />
        ) : (
          '**********'
        )}
      </div>
      <div className="pointer balance-icon" onClick={() => setShowBalance(!showBalance)}>
        <Icon className="font-weight-bold" name={showBalance ? 'i-Eye' : 'i-EyeClosed'} />
      </div>
    </div>
  ) : <></>
}