import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function WithdrawRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/finance/withdraw`,
    useInterceptor,
    withoutInterceptor
  )

  return {    
    canWithdraw() {
      return request.get<any>('check');
    },

    getAllWithdraw(params) {
      return request.get<any>('', params);
    },

    createWithdraw(payload) {
      return request.post<any>('', payload);
    },

    cancelWithdraw(id, payload) {
      return request.put<any>(`${id}/cancel`, payload);
    }
  }
}
