import React, { Suspense } from 'react';
import { map } from 'lodash';
import { Switch } from 'react-router-dom';

import { LazyPageComponent } from '@clodeo/clodeo-ui/components/page/lazy/lazy-page.component';

import { GuardRoute } from '../../components/guard-route/guard-route.component';
import { MainLayoutComponent } from '../../components/layout-component/main/main-layout.component';
import useLocalData from '../../hooks/useLocalData';
import { environment } from '../../../environments/environment';
import { Routes } from '../../routes/routes';

/* Dashboard */
const DashboardComponent = React.lazy(() => import('../../routes/Dashboard'));
/* End of Dashboard */

/* Invoice */
const InvoiceComponent = React.lazy(() => import('../../routes/Invoice'));
const InvoiceDetailComponent = React.lazy(() => import('../../routes/InvoiceDetail'));
/* End of Invoice */

/* Contact */
const ContactComponent = React.lazy(() => import('../../routes/Contact'));
const ContactImportComponent = React.lazy(() => import('../../routes/ContactImport'));
/* End of Contact */

/* User */
const UserListComponent = React.lazy(() => import('../../routes/User'));
const UserListImportComponent = React.lazy(() => import('../../routes/UserImport'));
/* End of User */

/* User Branch */
const UserBranchComponent = React.lazy(() => import('../../routes/UserBranch'));
const UserBranchImportComponent = React.lazy(() => import('../../routes/UserBranchImport'));
/* End of User Branch */

/* User Department */
const UserDepartmentComponent = React.lazy(() => import('../../routes/UserDepartment'));
const UserDepartmentImportComponent = React.lazy(() => import('../../routes/UserDepartmentImport'));
/* End of User Department */

/* Integration */
const Integration = React.lazy(() => import('../../routes/Integration'));
const IntegrationDetail = React.lazy(() => import('../../routes/IntegrationDetail'));
/* End of Integration */

/* Setting */
const BankInfoComponent = React.lazy(() => import('./setting/bank/setting-bank.component'));
const CompanyInfoComponent = React.lazy(() => import('./setting/company-info/setting-company-info.component'));
const SettingConfigurationCompanyComponent = React.lazy(() => import('./setting/configuration-company/setting-configuration-company.component'));
const SettingExpedition = React.lazy(() => import('./setting/expedition/setting-expedition.component'));
const SettingPrintShippingLabel = React.lazy(() => import('./setting/shipping-label'));
const SettingProfileComponent = React.lazy(() => import('./setting/profile/setting-profile.component'));
const SettingVerificationComponent = React.lazy(() => import('./setting/verification/setting-verification.component'));
/* End of Setting */

/* Balance */
const BalanceListComponent = React.lazy(() => import('./balance/balance/balance-list.component'));
/* End of Balance */

/* Withdraw */
const WithdrawListComponent = React.lazy(() => import('./balance/withdraw/withdraw-list.component'));
/* End of Withdraw */

/* Topup */
const TopupListComponent = React.lazy(() => import('./balance/topup/topup-list.component'));
/* End of Topup */

/* Check Shipment Price */
const CheckShipmentPrice = React.lazy(() => import('../../routes/CheckShipmentPrice'));
/* End of Check Shipment Price */

/* Shipment Order */
const NewShipmentOrder = React.lazy(() => import('../../routes/NewShipmentOrder'));
const ShipmentOrderCreate = React.lazy(() => import('../../routes/ShipmentOrderCreate'));
const ShipmentOrderUpdate = React.lazy(() => import('../../routes/ShipmentOrderUpdate'));
const NewShipmentOrderDetail = React.lazy(() => import('../../routes/NewShipmentOrderDetail'));
const ShipmentOrderImport = React.lazy(() => import('../../routes/ShipmentOrderImport'));
/* End of Shipment Order */

/* Return */
const ReturnPotensial = React.lazy(() => import('../../routes/ReturnPotensial'));
const Returning = React.lazy(() => import('../../routes/Returning'));
const ReceiptReturned = React.lazy(() => import('../../routes/ReceiptReturned'));
const TrackingAirwaybill = React.lazy(() => import('../../routes/TrackingAirwaybill'));
/* End of Return */

/* Report */
const ReportShipmentByCity = React.lazy(() => import('../../routes/ReportShipmentByCity'));
const ReportShipmentByCourier = React.lazy(() => import('../../routes/ReportShipmentByCourier'));
const ReportShipmentCOD = React.lazy(() => import('../../routes/ReportShipmentCOD'));
const ReportShipmentByStatus = React.lazy(() => import('../../routes/ReportShipmentByStatus'));
/* End of Report */

/* Unused */
// const Promo = React.lazy(() => import('../../routes/Promo'));
// const Analytic = React.lazy(() => import('../../routes/Analytic'));
const SampleBaseRfList = React.lazy(() => import('../../routes/SampleBaseRfList'));
/* End of unused */

export function MainRouter() {
  const { store: { relatedData } } = useLocalData();
  const userType = relatedData?.userType || 'B2C';
  const accessibleBalance = relatedData?.userCompany?.companyIsBalanceShow;
  const accessibleInvoice = relatedData?.userCompany?.companyIsAutoBilling;

  const financeList = [
    {
      title: 'Invoice',
      path: Routes.INVOICE,
      permissions: ['finance.invoice.view'],
      component: <InvoiceComponent />,
    },
    {
      title: 'Invoice Detail',
      path: `${Routes.INVOICE}/:id`,
      permissions: ['finance.invoice.view'],
      component: <InvoiceDetailComponent />,
    }
  ];

  const balanceList = [
    {
      title: 'Balance',
      path: Routes.BALANCE,
      permissions: ['balance.balance_history'],
      component: <BalanceListComponent />
    },
    {
      title: 'Top Up',
      path: Routes.TOPUP,
      permissions: ['balance.topup'],
      component: <TopupListComponent />
    },
    {
      title: 'Withdraw',
      path: Routes.WITHDRAW,
      permissions: ['balance.withdraw'],
      component: <WithdrawListComponent />
    }
  ];

  return (
    <>
      <MainLayoutComponent>
        <Suspense fallback={<LazyPageComponent />}>
          <Switch>
            <GuardRoute
              title="Dashboard"
              path={Routes.DASHBAORD}
              meta={{ permissions: ["dashboard.view"] }}
              exact
            >
              <DashboardComponent />
            </GuardRoute>

            {userType === 'B2B' && accessibleInvoice && map(financeList, (finance) => (
              <GuardRoute
                title={finance.title}
                path={finance.path}
                key={finance.path}
                meta={{ permissions: finance.permissions }}
                exact
              >
                {finance.component}
              </GuardRoute>
            ))}

            <GuardRoute
              title="New Order"
              path={Routes.SHIPMENT_ORDER}
              meta={{ permissions: ['order.view'] }}
              exact
            >
              <NewShipmentOrder />
            </GuardRoute>

            <GuardRoute
              title="Order Import"
              path={`${Routes.SHIPMENT_ORDER}/import`}
              meta={{ permissions: ['order.create'] }}
              exact
            >
              <ShipmentOrderImport />
            </GuardRoute>

            <GuardRoute
              title="Order Create"
              path={`${Routes.SHIPMENT_ORDER}/create`}
              meta={{ permissions: ['order.create'] }}
              exact
            >
              <ShipmentOrderCreate />
            </GuardRoute>

            <GuardRoute
              title="Order update"
              path={`${Routes.SHIPMENT_ORDER}/:id/update`}
              meta={{ permissions: ['order.edit'] }}
              exact
            >
              <ShipmentOrderUpdate />
            </GuardRoute>

            <GuardRoute
              title="Order Detail"
              path={`${Routes.SHIPMENT_ORDER}/:id`}
              meta={{ permissions: ['order.view'] }}
              exact
            >
              <NewShipmentOrderDetail />
            </GuardRoute>

            <GuardRoute
              title="Potensial Return"
              path={Routes.RETURN_POTENSIAL}
              meta={{ permissions: ['return_management.potential_return'] }}
              exact
            >
              <ReturnPotensial />
            </GuardRoute>

            <GuardRoute
              title="Cek Ongkir"
              path={Routes.CHECK_SHIPPING_COST}
              meta={{ permissions: ['other_feature.check_pricing'] }}
              exact
            >
              <CheckShipmentPrice />
            </GuardRoute>

            <GuardRoute
              title="Return Diterima"
              path={Routes.RECEIPT_RETURNED}
              meta={{ permissions: ['return_management.return_accepted'] }}
              exact
            >
              <ReceiptReturned />
            </GuardRoute>

            <GuardRoute
              title="Returning"
              path={Routes.RETURNING}
              exact
            >
              <Returning />
            </GuardRoute>

            <GuardRoute
              title="ui.menu.contact"
              path={Routes.CONTACT}
              meta={{ permissions: ['contact.view'] }}
              exact
            >
              <ContactComponent />
            </GuardRoute>

            <GuardRoute
              title="Contact Import"
              path={`${Routes.CONTACT}/import`}
              exact
            >
              <ContactImportComponent />
            </GuardRoute>

            <GuardRoute
              title="User List"
              meta={{ permissions: ['user.view'] }}
              path={Routes.USER}
              exact
            >
              <UserListComponent />
            </GuardRoute>

            <GuardRoute
              title="User Import"
              meta={{ permissions: ['user.create'] }}
              path={`${Routes.USER}/import`}
              exact
            >
              <UserListImportComponent />
            </GuardRoute>

            <GuardRoute
              title="User Branch List"
              path={Routes.BRANCH}
              meta={{ permissions: ['shipping_address.branch'] }}
              exact
            >
              <UserBranchComponent />
            </GuardRoute>

            <GuardRoute
              title="User Branch Import"
              path={`${Routes.BRANCH}/import`}
              meta={{ permissions: ['shipping_address.branch'] }}
              exact
            >
              <UserBranchImportComponent />
            </GuardRoute>

            <GuardRoute
              title="User Department List"
              path={`${Routes.DEPARTMENT}`}
              meta={{ permissions: ['shipping_address.department'] }}
              exact
            >
              <UserDepartmentComponent />
            </GuardRoute>

            <GuardRoute
              title="User Department Import"
              path={`${Routes.DEPARTMENT}/import`}
              meta={{ permissions: ['shipping_address.department'] }}
              exact
            >
              <UserDepartmentImportComponent />
            </GuardRoute>

            {/* <GuardRoute
              title="Promo"
              path={`${url}/promo`}
              exact
            >
              <Promo />
            </GuardRoute> */}

            <GuardRoute
              title="Setting Profile"
              path={Routes.PROFILE}
              exact
            >
              <SettingProfileComponent />
            </GuardRoute>

            {userType === 'B2B' && (
              <GuardRoute
                title="Integration API"
                path={Routes.INTEGRATION_API}
                exact
              >
                <Integration />
              </GuardRoute>
            )}

            {userType === 'B2B' && (
              <GuardRoute
                title="Integration API Detail"
                path={`${Routes.INTEGRATION_API}/:platform`}
                exact
              >
                <IntegrationDetail />
              </GuardRoute>
            )}

            <GuardRoute
              title="Setting Expedition"
              path={Routes.EXPEDITION}
              meta={{ permissions: ["other_feature.expedition"] }}
              exact
            >
              <SettingExpedition />
            </GuardRoute>

            <GuardRoute
              title="Setting Company"
              path={Routes.CONFIGURATION}
              exact
            >
              <SettingConfigurationCompanyComponent />
            </GuardRoute>

            <GuardRoute
              title="Print Shipping Label"
              path={Routes.SHIPPING_LABEL}
              meta={{ permissions: ["other_feature.shipping_label"] }}
              exact
            >
              <SettingPrintShippingLabel />
            </GuardRoute>

            <GuardRoute
              title="Verification"
              path={Routes.VERIFICATION}
              exact
            >
              <SettingVerificationComponent />
            </GuardRoute>

            <GuardRoute
              title="Perbankan"
              path={Routes.BANK}
              exact
            >
              <BankInfoComponent />
            </GuardRoute>

            <GuardRoute
              title="Perusahaan"
              path={Routes.COMPANY}
              exact
            >
              <CompanyInfoComponent />
            </GuardRoute>

            {accessibleBalance && map(balanceList, (balance) => (
              <GuardRoute
                title={balance.title}
                path={balance.path}
                key={balance.path}
                meta={{ permissions: balance.permissions }}
                exact
              >
                {balance.component}
              </GuardRoute>
            ))}

            {/* <GuardRoute
              title="Analytic"
              path={`/features/analytic`}
              exact
            >
              <Analytic />
            </GuardRoute> */}

            <GuardRoute
              title="Lacak Resi"
              path={Routes.CHECK_AIRWAYBILL}
              meta={{ permissions: ['other_feature.check_awb'] }}
              exact
            >
              <TrackingAirwaybill />
            </GuardRoute>

            {['local', 'development'].includes(environment.environmentName) && (
              <GuardRoute
                title="Tes"
                path={'/features/setting/sender/test'}
                exact
              >
                <SampleBaseRfList />
              </GuardRoute>
            )}

            <GuardRoute
              title="Laporan Pengiriman per Kota"
              path={`${Routes.REPORT}/city`}
              exact
            >
              <ReportShipmentByCity />
            </GuardRoute>

            <GuardRoute
              title="Laporan Pengiriman per Kurir"
              path={`${Routes.REPORT}/courier`}
              exact
            >
              <ReportShipmentByCourier />
            </GuardRoute>

            <GuardRoute
              title="Laporan Pengiriman COD"
              path={`${Routes.REPORT}/shipment-cod`}
              exact
            >
              <ReportShipmentCOD />
            </GuardRoute>

            <GuardRoute
              title="Laporan Pengiriman per Status"
              path={`${Routes.REPORT}/status`}
              exact
            >
              <ReportShipmentByStatus />
            </GuardRoute>

          </Switch>
        </Suspense>
      </MainLayoutComponent>
    </>
  );
}
