import Axios from 'axios';
import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function BalanceRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v1/finance/balance`,
    useInterceptor,
    withoutInterceptor
  )

  return {
    getAllBalanceDetail(params) {
      return request.get<any>('balance-detail', params);
    },

    getBalance(params?) {
      return request.get<any>('tenant-balance', params);
    },

    showBalanceTnC() {
      return request.post<any>('tnc');
    },

    downloadAttachment(url) {
      return Axios.get(url);
    }
  }
}
