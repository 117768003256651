import { compact } from 'lodash';
import { LocalStorageService } from '@clodeo/clodeo-core/local-storage/local-storage.service';
import { Routes } from '../../../routes/routes';
import { AclService } from '../../../core/auth/acl.service';
import { IconContact, IconDashboard, IconReport, IconSetting, IconShipdeoApps, IconShipment } from '../../icons/icons-component';

const localStorageService = new LocalStorageService();


export class MenuService {
  static get menus(): IMenu[] {
    const isOwner = localStorageService.getItem('currentUser')?.user?.isOwner;
    const { can } = new AclService();

    /**
      * Jumlah items di permissions dan routes harus sama dan berurutan. contoh: `determineRouteByPermissions(['dashboard.view', 'order.view'], [Routes.DASHBOARD, Routes.SHIPMENT_ORDER])`
      *
      * @param {Array<string>} permissions - List of permissions.
      * @param {Array<string>} routes - List of corresponding routes.
      * @returns {string} The determined route.
    */
    function determineRouteByPermissions(permissions: string[], routes: string[]) {
      for (let i = 0; i < permissions.length; i++) {
        if (can([permissions[i]])) {
          return routes[i];
        }
      }
      return Routes.FORBIDDEN_PAGE;
    }

    return compact([
      {
        id: 'dashboard',
        image: IconDashboard(),
        label: 'ui.menu.dashboard',
        to: Routes.DASHBAORD,
        permissions: ['dashboard.view', 'dashboard.balance', 'dashboard.invoice', 'dashboard.shipment_status', 'dashboard.delivering', 'dashboard.cod'],
      },
      {
        id: 'shipment',
        image: IconShipment(),
        label: 'ui.menu.shipment',
        permissions: ['order.view', 'return_management.potential_return', 'return_management.return_accepted', 'contact.view', 'other_feature.check_pricing', 'other_feature.check_awb'],
        subMenu: [
          {
            id: 'order',
            label: 'ui.menu.monitoring',
            to: Routes.SHIPMENT_ORDER,
            permissions: ['order.view'],
          },
          // {
          //   id: 'claim',
          //   label: 'ui.menu.claim',
          //   to: '/features/shipment/claim',
          //   permission: ['claim.view'],
          // },
          {
            id: 'return',
            label: 'ui.menu.return',
            to: determineRouteByPermissions(['return_management.potential_return', 'return_management.return_accepted'], [Routes.RETURN_POTENSIAL, Routes.RECEIPT_RETURNED]),
            permissions: ['return_management.potential_return', 'return_management.return_accepted'],
          },
          {
            id: 'shipping-cost',
            label: 'ui.menu.shippingCost',
            to: Routes.CHECK_SHIPPING_COST,
            permissions: ['other_feature.check_pricing'],
          },
          {
            id: 'check-airwaybill',
            label: 'ui.menu.checkAirwaybill',
            to: Routes.CHECK_AIRWAYBILL,
            permissions: ['other_feature.check_awb']
          }
        ]
      },
      // {
      //   id: 'analytic',
      //   icon: 'i-Activity',
      //   label: 'Analytic',
      //   to: '/features/analytic',
      // },
      // {
      //   id: 'report',
      //   icon: 'i-Notebook',
      //   label: 'ui.menu.report',
      //   to: '/features/report',
      // },
      {
        id: 'contact',
        image: IconContact(),
        label: 'ui.menu.contact',
        to: Routes.CONTACT,
        permissions: ['contact.view'],
      },
      {
        id: 'shipdeo-apps',
        image: IconShipdeoApps(),
        label: 'ui.menu.shipdeoApps',
        subMenu: [
          {
            id: 'integration',
            label: 'ui.menu.integration',
            to: Routes.INTEGRATION_API,
          }
        ],
      },
      {
        id: 'report',
        image: IconReport(),
        label: 'ui.menu.report',
        subMenu: [
          {
            id: 'city',
            label: 'ui.menu.reportCity',
            to: `${Routes.REPORT}/city`
          },
          {
            id: 'courier',
            label: 'ui.menu.reportCourier',
            to: `${Routes.REPORT}/courier`
          },
          {
            id: 'status',
            label: 'ui.menu.reportStatus',
            to: `${Routes.REPORT}/status`
          },
          {
            id: 'shipment-cod',
            label: 'ui.menu.reportShipmentCOD',
            to: `${Routes.REPORT}/shipment-cod`
          },
        ]
      },
      {
        id: 'setting',
        image: IconSetting(),
        label: 'ui.menu.setting',
        subMenu: [
          {
            id: 'branch',
            label: 'ui.menu.branch',
            to: determineRouteByPermissions(['shipping_address.branch', 'shipping_address.department'], [Routes.BRANCH, Routes.DEPARTMENT]),
            permissions: ["shipping_address.branch", "shipping_address.department"]
          },
          {
            id: 'user',
            label: 'ui.menu.user',
            to: Routes.USER,
            permissions: ["user.view"]
          },
          // {
          //   id: 'custom-import-order',
          //   label: 'ui.menu.customImportOrder',
          //   to: '/features/setting/custom-import-order',
          // },
          {
            id: 'shipping-label',
            label: 'ui.menu.shippinglabel',
            to: Routes.SHIPPING_LABEL,
            permissions: ["other_feature.shipping_label"]
          },
          {
            id: 'expedition',
            label: 'ui.menu.expedition',
            to: Routes.EXPEDITION,
            permissions: ["other_feature.expedition"]
          },
          {
            id: 'configuration',
            label: 'ui.menu.configuration',
            to: Routes.CONFIGURATION,
          },
        ],
      },
    ]);
  }
}
