import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function PotentialReturnRest(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {

  request = requestService.new(
    `${baseUrl['API_MAIN']}/v2/potential-return`,
    useInterceptor,
    withoutInterceptor
  );

  return {
    findAll(params, body: any = {}) {
      return request.post('query', body, params);
    },
    createConversation(data) {
      return request.post('conversation', data);
    },
    markAsReadConversation(orderId: string) {
      return request.get(`conversation/${orderId}`);
    }
  }

}
