import React from 'react'

import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';


export interface OnBoardingPopupProps {
  visible: boolean;
  onClose: () => void;
  onClickCompleteProfile: () => void;
}

export default function OnBoardingPopup(props: OnBoardingPopupProps) {
  const { visible, onClose, onClickCompleteProfile } = props || {};
  return (
    <Modal
      visible={visible}
      footer={false}
      onCancel={() => onClose()}
      width="40vw"
    >
      <div className="text-center">
        <h3 className="mb-4 font-weight-bold">Selamat Datang di Shipdeo!</h3>
        <p className="board-content">Shipdeo siap membantu seluruh kegiatan aktivitas pengirimanmu! Sebelum melangkah jauh, lengkapi informasi akunmu untuk memulai melakukan pengiriman.</p>
        <img className="img-fluid my-4" src="assets/img/Setting/img-setting-profile.png" alt="setting profile" />
        <div>
          <Button
            label="Lengkapi Sekarang"
            type="secondary"
            size="lg"
            onClick={() => {
              onClickCompleteProfile()
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
