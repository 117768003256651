import React, { useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';

import { isNil } from 'lodash';
import Favicon from 'react-favicon';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import '@clodeo/libs/core/translate/i18n';
import { LocalStorageService } from '@clodeo/clodeo-core/local-storage/local-storage.service';

import { AppRouter } from './app-router';
import { AclService } from './core/auth/acl.service';
import { AuthenticationService } from './core/auth/authentication.service';
import LocalDataContext from './core/context';
import { environment } from '../environments/environment';
import localDataReducer from './core/reducers/localdata';

import './app.scss';

export function App() {
  const acl = new AclService();
  const authentication = new AuthenticationService();
  const router = useHistory();
  const [store, dispatch] = useReducer(localDataReducer, { menus: [], currentUser: new LocalStorageService().getItem('currentUser') });
  const contextValue = useMemo(() => ({ store, dispatch }), [store, dispatch]);
  const title = `${environment.CONTENTS.TITLE}${!isNil(contextValue.store?.menuTitle) ? ` - ${contextValue.store?.menuTitle}` : ''}`;

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'currentUser') {
        if (isNil(event.newValue)) {
          authentication.logout();
        } else {
          const data = JSON.parse(event.newValue);
          if (data.user.phone) {
            authentication.user = data;
            acl.redirectAllowedMenu(router, true);
          } else {
            router.push('/register-phone');
          }
        }
      }
    });
  }, [])


  return (
    <LocalDataContext.Provider value={contextValue}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Favicon url={environment.CONTENTS.FAVICON} />
      <AppRouter />
    </LocalDataContext.Provider>
  );
}

export default withRouter(App)
 