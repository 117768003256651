import { RequestService } from './request.service';

const requestService = new RequestService();
let request: RequestService;

export default function DashboardRest(
  baseUrl,
  useInterceptor: any = true,
  withoutInterceptor: any = false
) {
  request = requestService.new(
    baseUrl['API_MAIN'],
    useInterceptor,
    withoutInterceptor
  );

  return {
    getCourierList() {
      return request.get<any>(`v1/couriers`);
    },

    getTotalOrderCourier(params: any) {
      return request.post<any>(`v1/dashboard/order-courier-total`, null, {
        params,
      });
    },

    getGrowthGeneral(params: any) {
      return request.post<any>(`v1/dashboard/order`, null, { params });
    },

    getGrowthCourier(params: any) {
      return request.post<any>(`v1/dashboard/order-courier-general`, null, {
        params,
      });
    },

    getTotalCourier(params: any) {
      return request.post<any>(`v1/dashboard/order-courier`, null, { params });
    },

    getTotalOrderToday(payload: any) {
      return request.post<any>(`v1/orders/daily-reports`, payload);
    },

    getTotalOrderCityByCourier(params: any) {
      return request.post<any>(`v1/dashboard/order/by-city`, null, { params });
    },

    getTotalOrderDelivered(params: any) {
      return request.get<any>(`v1/orders/tabulation`, { params });
    },
    
    getBalance() {
      return request.get<any>('v2/dashboard/balance');
    },

    getInvoice() {
      return request.get<any>('v2/dashboard/invoice');
    },

    getShipment(params) {
      return request.get<any>('v2/dashboard/shipment', { params });
    },
  
    getDelivery(params?: any) {
      return request.get<any>(`v2/dashboard/delivery`, { params });
    },

    getDeliveryCOD(params?: any) {
      return request.get<any>(`v2/dashboard/delivery-cod-value`, { params });
    },

    getRateTop(params?: any) {
      return request.get<any>(`v2/dashboard/rate-top`, { params });
    },

    sendRateTopExport(params?: any) {
      return request.get<any>(`v2/dashboard/rate-top/export`, { params });
    },
  };
}
