import React from 'react';

import { useHistory } from 'react-router-dom';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';

import useLocalData from '../../hooks/useLocalData';
import { UserStatusType } from '../../../global';

import './upgrade-status.scss';
import { Routes } from '../../routes/routes';

const Check = () => <Icon name="i-Check" className="text-check" />
const X = () => <Icon name="i-X" className="text-x" />

export default function UpgradeStatusComponent() {
  const { store: { relatedData }, dispatch } = useLocalData();
  const { push: redirectTo } = useHistory();
  const status: UserStatusType = relatedData?.status || '';
  const userType: 'B2C' | 'B2B' = relatedData?.userType || '';

  const hideVerificationButton = (['verified', 'upgraded', 'pending_upgrade', 'rejected_upgrade'] as UserStatusType[]).includes(status);
  const hideUpgradeButton = (['upgraded', 'incomplete_upgrade'] as UserStatusType[]).includes(status);
  const isB2CVerification = userType === 'B2C' && !(['incomplete_upgrade', 'pending_upgrade', 'upgraded', 'rejected_upgrade'] as UserStatusType[]).includes(status);

  const onUpgrade = status.includes('upgrade')

  function handleHideUpgradePopup() {
    dispatch({
      type: 'update',
      name: 'showUpgradePopup',
      value: false,
    });
  }

  function handleOnClickVerification() {

    dispatch({
      type: 'update',
      name: 'isB2CVerification',
      value: isB2CVerification,
    });

    dispatch({
      type: 'update',
      name: 'isUpgrade',
      value: false,
    })

    handleHideUpgradePopup();
    redirectTo(Routes.VERIFICATION);
  }

  function handleOnClickUpgrade() {

    dispatch({
      type: 'update',
      name: 'isB2CVerification',
      value: false,
    });

    dispatch({
      type: 'update',
      name: 'isUpgrade',
      value: !onUpgrade,
    })

    handleHideUpgradePopup();
    redirectTo(Routes.VERIFICATION);
  }

  return (
    <div className="upgrade-status-component p-3">
      <div className="upgrade-status-wrapper">
        <div>
          <div className="upgrade-row title p-2">
            <div>Benefit</div>
          </div>
          <div className="bg-upgrade-side p-2">
            <div className="upgrade-row sider">
              <div>Pengiriman Non-COD</div>
            </div>
            <div className="upgrade-row sider">
              <div>Pengiriman COD</div>
            </div>
            <div className="upgrade-row sider">
              <div>Mass upload order</div>
            </div>
            <div className="upgrade-row sider h-55px">
              <div>Bayar menggunakan Ovo/Shopeepay</div>
            </div>
            <div className="upgrade-row sider h-55px">
              <div>Bayar menggunakan Balance</div>
            </div>
            <div className="upgrade-row sider h-55px">
              <div>Bayar menggunakan Invoice</div>
            </div>
            <div className="upgrade-row sider">
              <div>Tarik dana balance</div>
            </div>
            <div className="upgrade-row sider h-90px">
              <div>Integrasi dengan webstore (shopify, woocommerce)</div>
            </div>
            <div className="upgrade-row sider">
              <div>Integrasi API</div>
            </div>
            <div className="upgrade-row sider h-68px">
              <div>Shipdeo customer Support</div>
            </div>
            <div className="upgrade-row sider h-60px">
              <div></div>
            </div>
          </div>
        </div>
        <div>
          <div className="upgrade-row title p-2">
            <div>Personal</div>
            <div>Verifikasi</div>
            <div>Business</div>
          </div>
          <div className="p-2">
            <div className="upgrade-row content">
              <div>Unlimited</div>
              <div>Unlimited</div>
              <div>Unlimited</div>
            </div>
            <div className="upgrade-row content">
              <div>10 Pengiriman</div>
              <div>Unlimited</div>
              <div>Unlimited</div>
            </div>
            <div className="upgrade-row content">
              <div><Check /></div>
              <div><Check /></div>
              <div><Check /></div>
            </div>
            <div className="upgrade-row content h-55px">
              <div><Check /></div>
              <div><Check /></div>
              <div><Check /></div>
            </div>
            <div className="upgrade-row content h-55px">
              <div><Check /></div>
              <div><Check /></div>
              <div><Check /></div>
            </div>
            <div className="upgrade-row content">
              <div><X /></div>
              <div><X /></div>
              <div><Check /></div>
            </div>
            <div className="upgrade-row content">
              <div><X /></div>
              <div><Check /></div>
              <div><Check /></div>
            </div>
            <div className="upgrade-row content h-90px">
              <div><X /></div>
              <div><X /></div>
              <div><Check /></div>
            </div>
            <div className="upgrade-row content">
              <div><X /></div>
              <div><X /></div>
              <div><Check /></div>
            </div>
            <div className="upgrade-row content h-68px">
              <div>24/7</div>
              <div>24/7</div>
              <div>24/7</div>
            </div>
            <div className="upgrade-row h-60px">
              <div></div>
              <div>
                {!hideVerificationButton && (
                  <Button type="primary" label="Verifikasi Akun" onClick={handleOnClickVerification} />
                )}
              </div>
              <div>
                {!hideUpgradeButton && (
                  <Button type="tertiary" label="Upgrade Akun" onClick={handleOnClickUpgrade} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
